.insight__image-part {
  margin-bottom: 2.4rem;
  border-radius: 2.4rem;
  height: 28.2rem;
  overflow: hidden;
}

.insight__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition: all 0.3s;
}

.insight__name {
  /* 32 Medium */
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  margin: 0 0 2.4rem;
}

.insight__label {
  /* 12 Medium */
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
  display: inline-block;
}

.insight__label--color {
  color: var(--color);
}

.c-insight:hover {
  text-decoration: none;
}
.c-insight:hover .insight__image {
  transform: scale(1.07);
}

@media only screen and (min-width: 580px) and (max-width: 980px) {
  .insight__image-part {
    height: auto;
  }

  .insight__name {
    /* 26 Medium */
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
  }
}
@media only screen and (max-width: 580px) {
  .insight__image-part {
    height: auto;
  }

  .insight__name {
    margin-bottom: 1.2rem;
  }
}