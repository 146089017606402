.c-about-us-text2 {
  margin-top: 16.4rem;
  margin-bottom: 12.4rem;
}

.about-us-text2__steps, ul.about-us-text2__steps {
  margin-block: 6.4rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.about-us-text2__steps::after, ul.about-us-text2__steps::after {
  position: absolute;
  content: "";
  height: 1px;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #A45EFF 49.9%, #FDB022 50%);
  z-index: 1;
}

.about-us-text2__step, li.about-us-text2__step {
  position: relative;
  z-index: 2;
}
.about-us-text2__step:not(:first-child), li.about-us-text2__step:not(:first-child) {
  border-left: solid 1.2rem #0C0D2F;
}
.about-us-text2__step:not(:last-child), li.about-us-text2__step:not(:last-child) {
  border-right: solid 1.2rem #0C0D2F;
}

@media only screen and (max-width: 980px) {
  .c-about-us-text2 {
    margin-block: 7.6rem;
  }
}