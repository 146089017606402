@import 'colors';
@import 'typography';
@import 'breakpoints';

.c-pills {
	display: inline-flex;
	border-radius: 10rem;
	border: 1px solid $colorCarbonGreyBg;
	gap: 0.8rem;
}

ul.c-pills { // Fight with ul.g-list-nelist
	padding: 0.4rem;
}

.pills__switch-item {
	flex: 0 0 auto;
}

.pills__button {
	@include Font12Medium;
	height: 3.2rem;
	color: $colorWhite;
	padding-inline: 2rem;
	transition: all 0.3s;
	border-radius: 10rem;
	cursor: pointer;

	&:not(.g-gradient):not(.pills__button--active) {
		background: rgba(white, 0);
	}

	&:not(.pills__button--active).g-gradient {
		background: rgba(white, 0);
	}

	&:hover:not(.pills__button--active) {
		background: rgba(white, 0.12);
	}
}

.pills__button--active:not(.g-gradient) {
	background: var(--color, $colorViolet);
}

@media only screen and (max-width: $breakpointXs) {

	.pills__button {
		padding-inline: 1.5rem;
	}


	.c-pills {
		gap: 0.4rem;
	}


}

