.c-home-neatly-capture {
  margin-top: 16.4rem;
  margin-bottom: 10rem;
}

.home-neatly-capture__heading {
  margin-bottom: 9.4rem;
}

.home-neatly-capture__box {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "label label" "schema text";
  column-gap: 4.4rem;
  row-gap: 2.4rem;
}

.home-neatly-capture__label-part {
  grid-area: label;
}

.home-neatly-capture__schema-part {
  grid-area: schema;
}

.home-neatly-capture__text-part {
  /* 20 Regular */
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  grid-area: text;
  color: #D4D4E5;
}

.home-neatly-capture__schema-img {
  width: 48.4rem;
  height: auto;
}

@media only screen and (max-width: 980px) {
  .home-neatly-capture__box {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: "label" "schema" "text";
  }

  .home-neatly-capture__schema-img {
    width: 100%;
  }
}
@media only screen and (max-width: 580px) {
  .c-home-neatly-capture {
    margin-block: 7.6rem;
  }
}