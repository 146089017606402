@import 'colors';
@import 'typography';
@import 'breakpoints';

.c-zug {
	margin-top: 12.4rem;
	position: relative;
	padding-bottom: 45rem;
}

.zug__body {
	max-width: 74rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	@include Font20Regular;
	line-height: 1.6;
	color: $colorDegis;
	position: relative;
	z-index: 2;
}

.zug__heading {
	margin-bottom: 3.2rem;
	color: $colorWhite;
}

.zug__text {
	margin-bottom: 12.4rem;
}

.zug__adress-badge {
	@include Font14Regular;
	margin-bottom: 1.6rem;
}

.zug__address-text {
	@include Font20Regular;
	line-height: 1.6;
}

.zug__bg {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: auto;
	z-index: 1;
	overflow: hidden;
	border-radius: 0 0 2.4rem 2.4rem;
	line-height: 1px;

	&::after {
		position: absolute;
		display: block;
		content: '';
		inset: 0;
		z-index: 2;
		background: linear-gradient(to bottom, $colorBlackPearl 3.79%, rgba($colorBlackPearl, 0) 66%);
	}
}

@media only screen and (max-width: $breakpointXs) {

	.c-zug {
		width: 100%;
		margin-top: 7.6rem;
		padding-inline: $paddingXs;
		box-sizing: border-box;
		padding-bottom: 85vw;
	}

	.zug__bg {
		border-radius: 0;
	}


}

@media only screen and (min-width: $breakpointXs) and (max-width: $breakpointSm) {

	.c-zug {
		padding-bottom: 20vw;
	}

	.zug__text {
		margin-bottom: 7.6rem;
	}

	.zug__heading {
		max-width: 50rem;
		margin-left: auto;
		margin-right: auto;
	}
}
