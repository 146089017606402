@import 'breakpoints';

.c-home-marquee {
	margin-top: 16rem;
	margin-bottom: 16rem;
}

@media only screen and (max-width: $breakpointSm) {
	.c-home-marquee {
		margin-bottom: 7.6rem;
		margin-top: 7.6rem;
	}
}
