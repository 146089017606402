.c-ready {
  margin-bottom: 20rem;
  margin-top: 9.6rem;
}

.ready__center {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ready__heading {
  font-size: 11.2rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
}

.ready__button-part {
  margin-top: 4.8rem;
}

@media only screen and (max-width: 980px) {
  .c-ready {
    margin-bottom: 14rem;
  }

  .ready__heading {
    font-size: 8rem;
  }

  .ready__button-part {
    margin-top: 4.8rem;
  }
}