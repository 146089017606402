@import 'breakpoints';

.c-solution-intro {
	margin-bottom: 16.4rem;
}

@media only screen and (max-width: $breakpointSm) {

	.c-solution-intro {
		margin-top: 7.6rem;
		margin-bottom: 7.6rem;
	}

}
