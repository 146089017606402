.c-data-repository-benefits {
  margin-block: 16.4rem;
}

.data-repository-benefits__heading {
  margin-bottom: 6.4rem;
  text-align: center;
}

.data-repository-benefits__subtitle {
  /* 24 Regular */
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.333;
  text-align: center;
  margin-bottom: 4.4rem;
}

.data-repository-benefits__box {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas: "f1 f1 f2 f2 f3 f3" "f4 f4 f4 f5 f5 f5";
  gap: 2.4rem;
}

.data-repository-benefits__field--1 {
  grid-area: f1;
  --stagger: 0;
}

.data-repository-benefits__field--2 {
  grid-area: f2;
  --stagger: 1;
}

.data-repository-benefits__field--3 {
  grid-area: f3;
  --stagger: 2;
}

.data-repository-benefits__field--4 {
  grid-area: f4;
  --stagger: 3;
}

.data-repository-benefits__field--5 {
  grid-area: f5;
  --stagger: 4;
}

.data-repository-benefits__swipe-help {
  display: none;
  /* 14 Medium */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
  margin-top: 1.6rem;
}

.data-repository-benefits__scroll-padding-placeholder {
  display: none;
}

.data-repository-benefits__animated .data-repository-benefits__field {
  transition: all 0.3s calc(var(--stagger, 0) * 0.05s);
}
.data-repository-benefits__animated:not(.data-repository-benefits__box--entered) .data-repository-benefits__field {
  opacity: 0;
  visibility: hidden;
  transform: translateY(5rem);
}

@media only screen and (min-width: 580px) and (max-width: 980px) {
  .data-repository-benefits__box {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto;
    grid-template-areas: "f1 f2" "f3 f4" "f5 f5";
    gap: 2.4rem;
  }
}
@media only screen and (max-width: 580px) {
  .c-data-repository-benefits {
    margin-block: 7.6rem;
  }

  .data-repository-benefits__box {
    grid-template-columns: repeat(5, 28rem) 1px;
    grid-template-rows: auto;
    grid-template-areas: "f1 f2 f3 f4 f5 .";
    gap: 2.4rem;
  }

  .data-repository-benefits__scroll-parent {
    width: 100%;
    overflow: auto;
    padding-bottom: 1.6rem;
    padding-left: 2.4rem;
    box-sizing: border-box;
  }

  .data-repository-benefits__swipe-help {
    padding-left: 2.4rem;
    display: block;
  }

  .data-repository-benefits__scroll-padding-placeholder {
    display: block;
  }
}