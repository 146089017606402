@import 'colors';
@import 'typography';
@import 'breakpoints';

.c-text-block {
	color: $colorDegis;
	@include Font16Regular;
	line-height: 1.75;
}

.text-block__text--center {
	text-align: center;
	list-style: inside;
}

.text-block__text {

	h2, h3 {
		margin-top: 3em;
		margin-bottom: 1.5em;
	}

	.small {
		font-size: 2.4rem;
	}

	.white {
		color: $colorWhite;
	}


	.text-block__image {
		max-width: 100%;
		margin-block: 8rem;
	}

	.text-block__image--round {
		border-radius: 2.4rem;
	}

}

.text-block__text--size-lg {
	font-size: 3.2rem;
}


.text-block__text--size-md {
	@include Font20Medium;
	line-height: 1.6;
}



@media only screen and (max-width: $breakpointXs) {

	.text-block__text--center {
		text-align: left;
		list-style: outside;
	}

	.text-block__text--size-lg {
		font-size: 2.4rem;
	}

	.text-block__text {

		h2, h3 {
			padding-left: 1em;
			text-indent: -1em;
		}

		.text-block__text-block__image {
			margin-block: 4rem;
		}

		.text-block__image--round {
			border-radius: 1.6rem;
		}

	}

}
