@import 'colors';

.g-checkbox {
	appearance: none;
	display: inline-block;
	width: 2rem;
	height: 2rem;

	border-radius: 0.6rem;
	border: 1px solid #D0D5DD;
	background: #FFF;
	position: relative;
	vertical-align: middle;

	&::after {
		content: '';
		display: block;
		transition: all 0.2s;
		width: 1.4rem;
		height: 1.4rem;
		position: absolute;
		left: 0.2rem;
		top: 0.2rem;
		background: $colorViolet;
		border-radius: 0.4rem;
	}

	&:not(:checked) {
		&::after {
			opacity: 0;
			visibility: hidden;
			transform: scale(0.7);
		}
	}

	&:checked {
		border-color: #BBB;
	}

	&:focus, &:hover {
		border-color: #999;
	}


}

.g-checkbox--touched:invalid, .g-form--touched.g-checkbox:invalid {
	border-color: $colorRed;
	background: rgba($colorRed, 0.3);
}
