@import 'typography';
@import 'breakpoints';

.c-about-us-color-block {
	margin-block: 12.4rem;
	padding-block: 18.4rem;
}

.about-us-color-block__heading {
	margin-bottom: 6.4rem;
}

.about-us-color-block__text {
	margin-bottom: 6.4rem;
	@include Font24Medium;
}

@media only screen and (max-width: $breakpointSm) {

	.c-about-us-color-block {
		padding-block: 7.6rem;
		margin-block: 7.6rem;
	}

	.about-us-color-block__text {
		margin-bottom: 5.4rem;
	}

}
