.c-contact-form {
  margin-top: 1.2rem;
  padding-top: 17.6rem;
}

.contact-form__box {
  display: flex;
  justify-content: space-between;
}

.contact-form__left {
  flex: 0 0 45rem;
}

.contact-form__above {
  font-size: 1.6rem;
}

.contact-form__heading {
  margin-top: 1.6rem;
}

.contact-form__right {
  flex: 0 0 48rem;
}

.contact-form__row {
  margin-bottom: 2.4rem;
  display: flex;
  gap: 3.2rem;
}

.contact-form__row--button {
  margin-top: 3.2rem;
}

.contact-form__pair {
  flex: 1 1 auto;
}

.contact-form__label {
  display: block;
  margin-bottom: 0.6rem;
  /* 14 Medium */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
}

.contact-form__label--required::after {
  content: "*";
  margin-left: 0.3em;
  opacity: 0.3;
  display: inline-block;
  line-height: 1;
  font-size: 125%;
  transform: translateY(0.4rem);
}

.contact-form__label--checkbox {
  display: inline;
  /* 14 Regular */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  vertical-align: middle;
  margin-left: 1.2rem;
}

.contact-form__control {
  display: block;
}

.contact-form__privacy-link {
  text-decoration: underline;
}

@media only screen and (max-width: 980px) {
  .c-contact-form {
    padding-top: 13.4rem;
  }

  .contact-form__heading {
    margin-top: 2.8rem;
  }

  .contact-form__box {
    display: block;
  }

  .contact-form__right {
    margin-top: 6.8rem;
  }

  .contact-form__row {
    flex-direction: column;
    gap: 2.4rem;
  }
}