@import 'breakpoints';

.c-mezoheading {
	margin-block: 16.4rem;
}

@media only screen and (max-width: $breakpointSm) {

	.c-mezoheading {
		margin-block: 7.6rem;
	}

}
