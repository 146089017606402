@import 'typography';
@import 'colors';
@import 'breakpoints';

.c-footer {
	margin-top: 20rem;
}

.footer__line {
	border-bottom: solid 1px rgba(white, 0.12);
}

.footer__box {
	display: grid;
	grid-template-columns: 42rem repeat(4, auto);
	grid-template-rows: auto auto;
	grid-template-areas: 'claim m1 m2 m3 m4' 'certs certs certs certs certs';
	justify-content: flex-start;
	column-gap: 9.6rem;
	row-gap: 1.2rem;
	padding-top: 6.8rem;
	padding-bottom: 4.8rem;
}

.footer__claim-part {
	grid-area: claim;
	@include Font24Regular;
}

.footer__col--1 {
	grid-area: m1;
}

.footer__col--2 {
	grid-area: m2;
}

.footer__col--3 {
	grid-area: m3;
}

.footer__col--4 {
	grid-area: m4;
}

.footer__col-heading {
	@include Font14Bold;
	margin-bottom: 1.8rem;
	display: block;
}

.footer__link {
	@include Font16Regular;
	display: block;
	padding: 0.6rem 0;
	color: $colorDegis;
	transition: all 0.2s;

	&:hover {
		text-decoration: none;
		color: $colorViolet;
	}
}

.footer__certs {
	@include Font12Medium;
	display: flex;
	align-items: center;
	gap: 2.4rem;
	grid-column: 1 / span 5;
	grid-area: certs;
}


.footer__cert {
	vertical-align: middle;
	margin-right: 0.8rem;
}

.footer__cert--iso {
	width: 4.4rem;
	height: auto;
}

.footer__cert--ch {
	width: 2.4rem;
	height: auto;
}


@media only screen and (max-width: $breakpointSm) {

	.c-footer {
		margin-top: 14rem;
	}

	.footer__box {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		column-gap: 2.4rem;
		row-gap: 4rem;
		padding-top: 4.8rem;
		grid-template-areas: 'claim claim' 'm4 m1' 'm2 m3' 'certs certs';
		padding-bottom: 3.2rem;
	}

	.footer__claim-part {
		@include Font20Regular;
		grid-column: 1 / span 2;
	}

	.footer__certs {
		grid-column: 1 / span 2;
		margin-top: 3rem;
	}

}
