$colorBlackPearl: #0C0D2F;    //  --Black-Pearl-II
$colorWhite: #FBFAFD;         //  --Porcelain
$colorViolet: #A45EFF;        //  --Violet
$colorVioletBg: #35175B;      //  --Violet-Bg
$colorVioletLightBg: #F4EEFD; //  --Violet-20
$colorBlue: #00B2FF;          //  --Blue
$colorBlue2: #456EFF;         //
$colorCarbonGrey: #E8E7EC;    //  --Carbon-10
$colorCarbonGreyBg: #2E2C48;  //  --Carbon-BG
$colorCarbon60: #737283;      //  --Carbon-60
$colorTeal: #18DDDF;          //  --Teal
$colorTealBg: #194158;        //  --Teal-BG
$colorTealLightBg: #D0FFFF;   //  --Teal-10
$colorDegis: #D4D4E5;         //  --Degis
$colorOrange: #FDB022;        //  --Orange
$colorOrangeBg: #47413A;      //  --Orange-Bg
$colorOrangeLightBg: #FBEEDF; //  --Orange-20
$colorRed: #D90000;
$colorGreen: #009900;
$colorLightGreen: #00D38A;
$colorLightGreenBg: #CCF2E5;
