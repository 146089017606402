@import 'colors';

.c-hamburger {
	width: 2rem;
	height: 2rem;
	fill: $colorWhite;
}

.hamburger__part {
	transition: all 0.4s cubic-bezier(.29,1.58,.78,1.41);
	transform-origin: 10px 10px;

}

.c-hamburger--open {
	.hamburger__part--1 {
		transform: translate(-2px, 2px)  rotate(45deg);
	}
	.hamburger__part--2 {
		transform:  translate(-2px, -2px) rotate(-45deg);
	}
}
