@import 'colors';
@import 'typography';

.g-box-field {
	background: $colorCarbonGreyBg;
	padding: 3.2rem;
	color: var(--field-text-color, #{$colorDegis});
	@include Font24Regular;
	border-radius: 2.4rem;
}

.g-box-field__symbol {
	margin-bottom: 8.4rem;
	line-height: 1px;
}
