.login-form__heading {
  color: #0C0D2F;
  margin-bottom: 1.2rem;
}

.login-form__login-subheading {
  color: #737283;
  /* 16 Regular */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 4.4rem;
}

.login-form__fields {
  margin-top: 4.4rem;
  margin-bottom: 2.4rem;
}

.login-form__field {
  display: block;
  margin-bottom: 1.2rem;
  position: relative;
}

.login-form__field-icon {
  position: absolute;
  left: 1.2rem;
  top: 1rem;
}

.login-form__field-icon-img {
  width: 2.4rem;
  height: 2.4rem;
}

.login-form__below-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* 14 Medium */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
  font-weight: 500;
  margin-bottom: 2.4rem;
}

.login-form__chkb {
  margin-right: 0.8rem;
}

.login-form__forgot {
  color: #A45EFF;
  text-align: right;
}
.login-form__forgot:hover, .login-form__forgot:focus {
  text-decoration: underline;
}

.login-form__legal {
  margin-top: 3.2rem;
  /* 14 Regular */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
}
.login-form__legal a {
  color: #A45EFF;
  text-decoration: underline;
}

@media only screen and (max-width: 389px) {
  .login-form__below-fields {
    flex-direction: column;
    gap: 1.6rem;
    align-items: stretch;
  }
}