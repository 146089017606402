.c-pills {
  display: inline-flex;
  border-radius: 10rem;
  border: 1px solid #2E2C48;
  gap: 0.8rem;
}

ul.c-pills {
  padding: 0.4rem;
}

.pills__switch-item {
  flex: 0 0 auto;
}

.pills__button {
  /* 12 Medium */
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.333;
  height: 3.2rem;
  color: #FBFAFD;
  padding-inline: 2rem;
  transition: all 0.3s;
  border-radius: 10rem;
  cursor: pointer;
}
.pills__button:not(.g-gradient):not(.pills__button--active) {
  background: rgba(255, 255, 255, 0);
}
.pills__button:not(.pills__button--active).g-gradient {
  background: rgba(255, 255, 255, 0);
}
.pills__button:hover:not(.pills__button--active) {
  background: rgba(255, 255, 255, 0.12);
}

.pills__button--active:not(.g-gradient) {
  background: var(--color, #A45EFF);
}

@media only screen and (max-width: 580px) {
  .pills__button {
    padding-inline: 1.5rem;
  }

  .c-pills {
    gap: 0.4rem;
  }
}