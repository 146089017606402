.gauge__box {
  display: grid;
  grid-template-rows: 2rem 2rem;
  grid-template-columns: 1fr 5.6rem;
  grid-template-areas: "label label" "bar number";
  gap: 1.2rem 2.4rem;
}

.gauge__number {
  grid-area: number;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  line-height: 2rem;
}

.gauge__label {
  /* 16 Medium */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  grid-area: label;
  align-self: center;
}

.gauge__bar {
  background: #2E2C48;
  border-radius: 0.4rem;
  position: relative;
  transform-origin: left center;
  transition: transform 0.5s, opacity 0.5s;
}

.gauge__bar-part {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(100% * var(--val, 0) / 100);
  border-radius: 0.4rem;
  background: #555286;
  transition: transform 0.5s 0.3s, opacity 0.5s 0.3s, width 0.5s;
  transform-origin: left center;
}

.c-gauge--important .gauge__bar-part {
  background: linear-gradient(90deg, #A45EFF 2.44%, #456EFF 164.63%);
}

.c-gauge--unloaded .gauge__bar {
  transform: scaleX(0.01);
}
.c-gauge--unloaded .gauge__bar-part {
  transform: scaleX(0.01);
  opacity: 0;
}