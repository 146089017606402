@import 'colors';

.g-white-block {
	border-radius: 2.4rem;
	background: $colorWhite;
	padding-block: 8.4rem;
	color: $colorBlackPearl;
}

.g-white-block--violet {
	background: linear-gradient(90deg, $colorViolet 2.44%, $colorBlue2 164%);
	color: white;
}
