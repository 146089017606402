.c-zug {
  margin-top: 12.4rem;
  position: relative;
  padding-bottom: 45rem;
}

.zug__body {
  max-width: 74rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* 20 Regular */
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  line-height: 1.6;
  color: #D4D4E5;
  position: relative;
  z-index: 2;
}

.zug__heading {
  margin-bottom: 3.2rem;
  color: #FBFAFD;
}

.zug__text {
  margin-bottom: 12.4rem;
}

.zug__adress-badge {
  /* 14 Regular */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 1.6rem;
}

.zug__address-text {
  /* 20 Regular */
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  line-height: 1.6;
}

.zug__bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 1;
  overflow: hidden;
  border-radius: 0 0 2.4rem 2.4rem;
  line-height: 1px;
}
.zug__bg::after {
  position: absolute;
  display: block;
  content: "";
  inset: 0;
  z-index: 2;
  background: linear-gradient(to bottom, #0C0D2F 3.79%, rgba(12, 13, 47, 0) 66%);
}

@media only screen and (max-width: 580px) {
  .c-zug {
    width: 100%;
    margin-top: 7.6rem;
    padding-inline: 2.4rem;
    box-sizing: border-box;
    padding-bottom: 85vw;
  }

  .zug__bg {
    border-radius: 0;
  }
}
@media only screen and (min-width: 580px) and (max-width: 980px) {
  .c-zug {
    padding-bottom: 20vw;
  }

  .zug__text {
    margin-bottom: 7.6rem;
  }

  .zug__heading {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
}