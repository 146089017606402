.c-main-menu {
  box-sizing: border-box;
}

.main-menu__menu-bg {
  transition: background-color 0.2s;
}

.main-menu__box {
  display: grid;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: 28rem 1fr 28rem;
}

.main-menu__logo, .main-menu__home, .main-menu__logo-space {
  line-height: 1px;
  width: 10rem;
  height: 2.4rem;
}

.main-menu__logo-space {
  position: relative;
}

.main-menu__home {
  height: 100%;
}

.main-menu__logo {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transition: all 0.3s;
}

.main-menu__logo--dark {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.main-menu__logo--all-white {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.main-menu__expander-part {
  grid-column: -1/span 1;
  justify-self: flex-end;
}

.main-menu__expander {
  gap: 1.6rem;
}

@media only screen and (max-width: 1040px) {
  .main-menu__box {
    grid-template-columns: auto auto auto;
  }
}
@media only screen and (min-width: 980px) {
  .c-main-menu {
    padding-top: 2.4rem;
    height: 6.4rem;
  }

  .main-menu__expander-part {
    display: none;
  }

  .c-main-menu .main-menu__menu-bg {
    background: #FBFAFD;
    border-radius: 10rem;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .c-main-menu .main-menu__menu-box {
    display: flex;
    padding-inline: 1.1rem;
    align-items: center;
  }

  .main-menu__menu-box--aux {
    gap: 2.4rem;
  }

  .main-menu__link {
    display: flex;
    color: #0C0D2F;
    /* 12 Medium */
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.333;
    height: 4rem;
    align-items: center;
    justify-content: center;
    padding-inline: 2rem;
    transition: all 0.2s;
  }
  .main-menu__link:hover, .main-menu__link:focus {
    text-decoration: none;
    color: #A45EFF;
  }

  .main-menu__link--aux {
    color: #FBFAFD;
    /* 12 Medium */
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.333;
  }

  .main-menu__aux-part {
    justify-self: flex-end;
  }

  .main-menu__motto-part {
    display: none;
  }

  .body-white-background .main-menu__menu-bg {
    background: #0C0D2F;
  }
  .body-white-background .main-menu__link {
    color: #FBFAFD;
  }
  .body-white-background .main-menu__link:hover, .body-white-background .main-menu__link:focus {
    color: #A45EFF;
  }
  .body-white-background .main-menu__link--aux {
    color: #0C0D2F;
  }
  .body-white-background .main-menu__logo--light {
    opacity: 0;
    visibility: hidden;
  }
  .body-white-background .main-menu__logo--dark {
    opacity: 1;
    visibility: visible;
  }

  .body-violet-background .main-menu__logo--light {
    opacity: 0;
    visibility: hidden;
  }
  .body-violet-background .main-menu__logo--all-white {
    opacity: 1;
    visibility: visible;
  }
}
@media only screen and (max-width: 980px) {
  .c-main-menu {
    padding-top: 2.4rem;
    height: 6.4rem;
    overflow: hidden;
    transition: all 0.4s;
  }

  .main-menu__box {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 4rem auto 1fr auto;
    grid-template-areas: "logo expander" "motto motto" "menu menu" "aux aux";
    height: calc(100dvh - 2.4rem);
    padding-bottom: 6rem;
    box-sizing: border-box;
  }

  .main-menu__logo-space {
    grid-area: logo;
  }

  .main-menu__expander-part {
    grid-area: expander;
  }

  .main-menu__aux-part {
    grid-area: aux;
    margin-top: 3.6em;
    justify-self: center;
    text-align: center;
    color: #0C0D2F;
    transition: all 0.3s 0.3s;
  }

  .main-menu__menu-part {
    margin-top: 4rem;
    grid-area: menu;
    align-self: flex-start;
    transition: all 0.3s 0.2s;
  }

  .main-menu__motto-part {
    /* 12 Medium */
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.333;
    color: #0C0D2F;
    grid-area: motto;
    margin-top: 2.4rem;
    transition: all 0.3s 0.1s;
  }

  .main-menu__link:not(.main-menu__link--aux) {
    display: block;
    padding-block: 2.4rem;
    /* 24 Regular */
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.333;
    color: #0C0D2F;
  }

  .main-menu__menu-box--aux {
    display: flex;
    gap: 1.2rem;
    flex-direction: column-reverse;
  }

  .c-main-menu--open {
    background: #FBFAFD;
    height: 100dvh;
    left: 0;
    right: 0;
  }
  .c-main-menu--open .main-menu__logo--dark {
    opacity: 1;
    visibility: visible;
  }
  .c-main-menu--open .main-menu__logo--light {
    opacity: 0;
    visibility: hidden;
  }
  .c-main-menu--open .main-menu__logo--all-white {
    opacity: 0;
    visibility: hidden;
  }

  .body-white-background .main-menu__logo--light {
    opacity: 0;
    visibility: hidden;
  }
  .body-white-background .main-menu__logo--dark {
    opacity: 1;
    visibility: visible;
  }
  .body-white-background .main-menu__logo--all-white {
    opacity: 0;
    visibility: hidden;
  }

  .body-violet-background .main-menu__logo--light {
    opacity: 0;
    visibility: hidden;
  }
  .body-violet-background .main-menu__logo--dark {
    opacity: 0;
    visibility: hidden;
  }
  .body-violet-background .main-menu__logo--all-white {
    opacity: 1;
    visibility: visible;
  }

  .c-main-menu:not(.c-main-menu--open) .main-menu__aux-part, .c-main-menu:not(.c-main-menu--open) .main-menu__menu-part, .c-main-menu:not(.c-main-menu--open) .main-menu__motto-part {
    opacity: 0;
    transform: translateY(-2rem);
  }
}
@media only screen and (max-width: 980px) and (max-height: 770px) {
  .main-menu__link:not(.main-menu__link--aux) {
    padding-block: 1.8rem;
  }

  .main-menu__menu-part {
    margin-top: 2.4rem;
  }

  .main-menu__aux-part {
    margin-top: 3rem;
  }
}
@media only screen and (max-width: 980px) and (max-height: 670px) {
  .main-menu__link:not(.main-menu__link--aux) {
    padding-block: 1.4rem;
  }

  .main-menu__menu-part {
    margin-top: 2rem;
  }

  .main-menu__aux-part {
    margin-top: 2rem;
  }
}