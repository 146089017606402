@import 'breakpoints';

// Desktop, REM-based versions

@media only screen and (min-width: $breakpointSm) {

	.g-container {
		margin-left: auto;
		margin-right: auto;
		width: $containerWidth;
		box-sizing: border-box;
	}

	.g-container-xxl {
		margin-left: auto;
		margin-right: auto;
		width: $containerWidthXxl;
		max-width: calc(100% - 2 * 4.8rem);
		box-sizing: border-box;
	}

	.g-container-narrow {
		margin-left: auto;
		margin-right: auto;
		width: $containerWidthNarrow;
		box-sizing: border-box;
	}

	.g-container-full {
		width: calc(100% - 2 * #{$paddingFullWidth});
		margin-left: auto;
		margin-right: auto;
	}

	.g-container-narrow__wide-part {
		width: $containerWidth;
		margin-left: -1 * ($containerWidth - $containerWidthNarrow) / 2;
		margin-right: -1 * ($containerWidth - $containerWidthNarrow) / 2;
	}

}


// Mobile

@media only screen and (max-width: $breakpointXs) {

	.g-container, .g-container-narrow {
		width: 100%;
		padding-left: $paddingXs;
		padding-right: $paddingXs;
		box-sizing: border-box;
	}

	.g-container-xxl {
		width: 100%;
		padding-left: $paddingXs;
		padding-right: $paddingXs;
		box-sizing: border-box;
	}


	.g-container-full {
		width: calc(100% - 2 * #{$paddingFullWidth});
		margin-left: auto;
		margin-right: auto;
	}

	.g-container--no-mobile-padding {
		padding-left: 0;
		padding-right: 0;
	}

}


// Tablet

@media only screen and (max-width: $breakpointSm) and (min-width: $breakpointXs) {

	.g-container, .g-container-narrow {
		width: $containerWidthSm;
		box-sizing: border-box;
		margin-left: auto;
		margin-right: auto;
	}

	.g-container--allow-tablet-grow {
		width: $containerWidthSm + 12rem;
		max-width: calc(100% - 2 * #{$paddingXs});
	}

	.g-container-xxl {
		margin-left: auto;
		margin-right: auto;
		width: calc(100% - 2 * #{$paddingXs});
		box-sizing: border-box;
	}

	.g-container-xxl--no-tablet {
		padding-left: $paddingXs;
		padding-right: $paddingXs;
		width: 100%;
		box-sizing: border-box;
	}

	.g-container-full {
		width: calc(100% - 2 * #{$paddingFullWidth});
		margin-left: auto;
		margin-right: auto;
	}



}
