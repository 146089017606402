.c-home-subheading {
  margin-top: 18rem;
  margin-bottom: 11.2rem;
}

.home-subheading__heading {
  /* 40 Medium */
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}

.home-subheading__see {
  margin-top: 8.4rem;
  /* 32 Medium */
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}

.home-subheading__text {
  margin-top: 4rem;
  display: flex;
  gap: 2.4rem;
  color: #D4D4E5;
  /* 16 Regular */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
}

.home-subheading__col {
  flex: 1 1 0;
}

@media only screen and (max-width: 580px) {
  .c-home-subheading {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem;
  }

  .home-subheading__heading {
    /* 32 Medium */
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
  }

  .home-subheading__see {
    margin-top: 7rem;
  }

  .home-subheading__text {
    margin-top: 3.6rem;
    gap: 3.2rem;
    flex-direction: column;
  }
}