@import 'typography';
@import 'colors';
@import 'breakpoints';

.c-marquee-footer {
	margin-top: 24rem;
	margin-bottom: 22rem;
}

.marquee-footer__body {
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	margin-top: 4.8rem;
}

.marquee-footer__big {
	font-size: 12.4rem;
	font-weight: 500;
	margin-bottom: 0.8rem;
}

.marquee-footer__sub {
	color: rgba($colorDegis, 0.42);
	font-size: 3.4rem;
	line-height: 1.35;
}


// Tablet and mobile
@media only screen and (max-width: $breakpointSm) {

	.c-marquee-footer {
		margin-top: 7.6rem;
		margin-bottom: 14rem;
	}

	.marquee-footer__body {
		margin-top: 3.2rem;
	}

	.marquee-footer__big {
		font-size: 8rem;
	}

}

// Tablet only
@media only screen and (min-width: $breakpointXs) and (max-width: $breakpointSm) {
	.c-marquee-footer {
		margin-top: 12rem;
	}
}

// Mobile

@media only screen and (max-width: $breakpointXs) {



	.marquee-footer__body {
		width: 100%;
		box-sizing: border-box;
		margin-top: 1.2rem;
		padding-inline: $paddingXs;
	}

	.marquee-footer__sub {
		@include Font24Medium;
		max-width: 17ch;
	}

}
