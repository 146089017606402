/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
html {
  font-size: 62.5%;
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

html, body {
  font-family: "DM Sans", sans-serif, sans-serif;
  color: #FBFAFD;
}

html, body {
  font-size: 16px;
}

/* REM size */
@media only screen and (max-width: 980px) {
  html, body {
    font-size: 10px;
    --rem-ratio: 1;
    --layout-type: tablet;
  }
}
@media only screen and (max-width: 580px) {
  html, body {
    --layout-type: mobile;
  }
}
@media only screen and (min-width: 980px) {
  html, body {
    font-size: 8.5px;
    --rem-ratio: 0.85;
    --layout-type: desktop;
  }
}
@media only screen and (min-width: 1220px) and (min-height: 550px) {
  html, body {
    font-size: 10px;
    --rem-ratio: 1;
    --layout-type: desktop;
  }
}
@media only screen and (min-width: 1600px) and (min-height: 800px) {
  html, body {
    font-size: 12px;
    --rem-ratio: 1.2;
    --layout-type: desktop;
  }
}
html, body {
  background: #0C0D2F;
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

hr {
  margin-block: 6.4rem;
  border: none;
  border-top: solid 1px rgba(251, 250, 253, 0.12);
}

@media only screen and (max-width: 980px) {
  hr {
    margin-block: 4.8rem;
  }
}
.g-color-orange {
  --color: #FDB022;
  --color-bg: #47413A;
  --color-light-bg: #FBEEDF;
}

.g-color-teal {
  --color: #18DDDF;
  --color-bg: #194158;
  --color-light-bg: #D0FFFF;
}

.g-color-violet {
  --color: #A45EFF;
  --color-bg: #35175B;
  --color-light-bg: #F4EEFD;
}

.g-color-green {
  --color: #00D38A;
  --color-bg: #CCF2E5;
  --color-light-bg: #CCF2E5;
}

@media only screen and (min-width: 980px) {
  .g-container {
    margin-left: auto;
    margin-right: auto;
    width: 103.2rem;
    box-sizing: border-box;
  }

  .g-container-xxl {
    margin-left: auto;
    margin-right: auto;
    width: 156rem;
    max-width: calc(100% - 2 * 4.8rem);
    box-sizing: border-box;
  }

  .g-container-narrow {
    margin-left: auto;
    margin-right: auto;
    width: 50.4rem;
    box-sizing: border-box;
  }

  .g-container-full {
    width: calc(100% - 2 * 1.2rem);
    margin-left: auto;
    margin-right: auto;
  }

  .g-container-narrow__wide-part {
    width: 103.2rem;
    margin-left: -26.4rem;
    margin-right: -26.4rem;
  }
}
@media only screen and (max-width: 580px) {
  .g-container, .g-container-narrow {
    width: 100%;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    box-sizing: border-box;
  }

  .g-container-xxl {
    width: 100%;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    box-sizing: border-box;
  }

  .g-container-full {
    width: calc(100% - 2 * 1.2rem);
    margin-left: auto;
    margin-right: auto;
  }

  .g-container--no-mobile-padding {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 980px) and (min-width: 580px) {
  .g-container, .g-container-narrow {
    width: 52rem;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
  }

  .g-container--allow-tablet-grow {
    width: 64rem;
    max-width: calc(100% - 2 * 2.4rem);
  }

  .g-container-xxl {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 2 * 2.4rem);
    box-sizing: border-box;
  }

  .g-container-xxl--no-tablet {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    width: 100%;
    box-sizing: border-box;
  }

  .g-container-full {
    width: calc(100% - 2 * 1.2rem);
    margin-left: auto;
    margin-right: auto;
  }
}
.g-h1 {
  /* 64 Medium */
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  margin-block: 0;
}

.g-h2 {
  /* 40 Medium */
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  margin-block: 0;
}

.g-h2-bigger {
  /* 44 Medium */
  font-size: 4.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  line-height: 1.5;
  margin-block: 0;
}

.g-h3 {
  /* 32 Medium */
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  margin-block: 0;
}

.g-h4 {
  /* 24 Regular */
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.333;
  margin-block: 0;
}

.g-h4--bold {
  /* 24 Bold */
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
}

@media only screen and (max-width: 980px) {
  .g-h1 {
    /* 36 Medium */
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
  }

  .g-h2-bigger {
    /* 32 Medium */
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    line-height: 1.5;
  }

  .g-h2 {
    /* 28 Medium */
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
  }

  .g-h3 {
    /* 24 Regular */
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.333;
  }
}
ul.g-list-nelist {
  padding: 0;
  margin: 0;
}
ul.g-list-nelist > li {
  list-style: none;
}

.g-button-nebutton {
  border: none;
  background: none;
  box-shadow: none;
  border-radius: 0;
  appearance: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
}

.g-button {
  border: none;
  background: none;
  box-shadow: none;
  border-radius: 0;
  appearance: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  /* 12 Medium */
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.333;
  font-weight: bold;
  height: 4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-inline: 3.2rem;
  box-sizing: border-box;
  transition: all 0.3s;
  border-radius: 10rem;
}
.g-button:hover {
  text-decoration: none;
}

/* COLOR TYPES */
.g-button--outline {
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.16);
}
.g-button--outline:hover, .g-button--outline:focus {
  background: #A45EFF;
  border-color: #A45EFF;
}

.body-white-background .g-button--outline--violet-on-white-bg {
  background-color: #A45EFF;
  color: white;
  backdrop-filter: blur(6px);
}
.body-white-background .g-button--outline--violet-on-white-bg:hover, .body-white-background .g-button--outline--violet-on-white-bg:focus, .body-white-background .g-button--outline--violet-on-white-bg.g-button--active {
  background-color: rgba(164, 94, 255, 0.8);
}

.g-button--grey {
  background: rgba(156, 118, 232, 0.12);
  color: #FBFAFD;
  backdrop-filter: blur(6px);
}
.g-button--grey:hover, .g-button--grey:focus, .g-button--grey.g-button--active {
  background-color: rgba(251, 250, 253, 0.24);
}

.body-white-background .g-button--grey--dark-on-white-bg {
  background-color: rgba(12, 13, 47, 0.8);
  color: #FBFAFD;
  backdrop-filter: blur(6px);
}
.body-white-background .g-button--grey--dark-on-white-bg:hover, .body-white-background .g-button--grey--dark-on-white-bg:focus, .body-white-background .g-button--grey--dark-on-white-bg.g-button--active {
  background-color: #737283;
}

.g-button--violet {
  background-color: #A45EFF;
  color: white;
  backdrop-filter: blur(6px);
}
.g-button--violet:hover, .g-button--violet:focus, .g-button--violet.g-button--active {
  background-color: rgba(164, 94, 255, 0.8);
}

.g-button--green {
  background-color: #009900;
  color: white;
}

.g-button--dark {
  background: #0C0D2F;
  color: #FBFAFD;
  backdrop-filter: blur(6px);
}
.g-button--dark:hover, .g-button--dark:focus, .g-button--dark.g-button--active {
  background-color: #161858;
}

.g-button--darken {
  background: rgba(8, 9, 39, 0.12);
  color: #FBFAFD;
}
.g-button--darken:hover, .g-button--darken:focus, .g-button--darken.g-button--active {
  background: rgba(8, 9, 39, 0.3);
}

.g-button--lighten {
  background: rgba(251, 250, 253, 0.12);
  color: #FBFAFD;
}
.g-button--lighten:hover, .g-button--lighten:focus, .g-button--lighten.g-button--active {
  background: rgba(251, 250, 253, 0.25);
}

.g-button--text {
  background: none;
  /* 14 Medium */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
}
.g-button--text:hover, .g-button--text:focus, .g-button--text.g-button--active {
  color: var(--color, #A45EFF);
}

/* SIZES */
.g-button--sm {
  height: 3.6rem;
}

.g-button--lg {
  font-size: 1.6rem;
  height: 4.8rem;
}

.g-button--xl {
  font-size: 4rem;
  font-weight: 500;
  height: 10.4rem;
  padding-inline: 4.8rem;
}

.g-button--narrow {
  padding-inline: 2.2rem;
}

.g-button--block {
  width: 100%;
}

.g-button--icon {
  display: inline-flex;
  width: 4.8rem;
  height: 4.8rem;
  justify-content: center;
  padding: 0;
  margin: 0;
  align-items: center;
  border-radius: 10rem;
  background: rgba(251, 250, 253, 0.12);
  backdrop-filter: blur(4px);
}

/* DECORATIONS */
.g-button--no-pl {
  padding-left: 0.1rem;
}

.g-button--arrow::after {
  content: "";
  width: 2.4rem;
  height: 2.4rem;
  background: url("../imgs/chevron-right.svg") no-repeat center center;
  background-size: contain;
  margin-left: 1.2rem;
  margin-right: -0.8rem;
}
.g-button--arrow:hover::after, .g-button--arrow:hover .g-button__arrow, .g-button--arrow:focus::after, .g-button--arrow:focus .g-button__arrow, .g-button--arrow.g-button--active::after, .g-button--arrow.g-button--active .g-button__arrow {
  animation: arrow-bounce infinite alternate 0.4s;
}

.g-button--xl.g-button--arrow::after {
  width: 4.8rem;
  height: 4.8rem;
  margin-left: 1.6rem;
  margin-right: -1.6rem;
  background-image: url("../imgs/arrow-right.svg");
}

.g-button--arrow-left::before {
  content: "";
  width: 2.4rem;
  height: 2.4rem;
  background: url("../imgs/chevron-left.svg") no-repeat center center;
  background-size: contain;
  margin-right: 1.2rem;
  margin-left: -0.8rem;
}
.g-button--arrow-left:hover::before, .g-button--arrow-left:hover .g-button__arrow, .g-button--arrow-left:focus::before, .g-button--arrow-left:focus .g-button__arrow, .g-button--arrow-left.g-button--active::before, .g-button--arrow-left.g-button--active .g-button__arrow {
  animation: arrow-bounce-left infinite alternate 0.4s;
}

.g-button--xl.g-button--arrow-left::before {
  width: 4.8rem;
  height: 4.8rem;
  margin-right: 1.6rem;
  margin-left: -1.6rem;
  background-image: url("../imgs/arrow-left.svg");
}

.g-button--arrow--color::after {
  display: none;
}
.g-button--arrow--color .g-button__arrow {
  margin-left: 1.2rem;
  width: 2.4rem;
  height: 2.4rem;
  color: var(--color);
}

.g-button--status-icon {
  background-size: 2.4rem auto;
  background-repeat: no-repeat;
  background-position: right 1.2rem center;
}

.g-button--sending {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgc3R5bGU9Im1hcmdpbjogYXV0bzsgYmFja2dyb3VuZDogbm9uZTsgZGlzcGxheTogYmxvY2s7IHNoYXBlLXJlbmRlcmluZzogYXV0bzsiIHdpZHRoPSIyMDBweCIgaGVpZ2h0PSIyMDBweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIj4NCjxjaXJjbGUgY3g9IjUwIiBjeT0iNTAiIHI9IjQxIiBzdHJva2Utd2lkdGg9IjE0IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS1kYXNoYXJyYXk9IjY0LjQwMjY0OTM5ODU5MDc1IDY0LjQwMjY0OTM5ODU5MDc1IiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiPg0KICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGR1cj0iMS41MTUxNTE1MTUxNTE1MTUxcyIga2V5VGltZXM9IjA7MSIgdmFsdWVzPSIwIDUwIDUwOzM2MCA1MCA1MCI+PC9hbmltYXRlVHJhbnNmb3JtPg0KPC9jaXJjbGU+DQo8L3N2Zz4=);
}

.g-button--success {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiPjxwYXRoIGQ9Ik0zMiwyQzE1LjQzMSwyLDIsMTUuNDMyLDIsMzJjMCwxNi41NjgsMTMuNDMyLDMwLDMwLDMwYzE2LjU2OCwwLDMwLTEzLjQzMiwzMC0zMEM2MiwxNS40MzIsNDguNTY4LDIsMzIsMnogTTI1LjAyNSw1MA0KCWwtMC4wMi0wLjAyTDI0Ljk4OCw1MEwxMSwzNS42bDcuMDI5LTcuMTY0bDYuOTc3LDcuMTg0bDIxLTIxLjYxOUw1MywyMS4xOTlMMjUuMDI1LDUweiIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}

@keyframes arrow-bounce {
  from {
    transform: none;
  }
  to {
    transform: translateX(1rem);
  }
}
@keyframes arrow-bounce-left {
  from {
    transform: none;
  }
  to {
    transform: translateX(-1rem);
  }
}
@media only screen and (max-width: 980px) {
  .g-button--xl-small-sm {
    font-size: 3.2rem;
    padding-inline: 3.2rem;
  }

  .g-button--xl-small-sm.g-button--arrow::after {
    width: 4rem;
    height: 4rem;
  }
}
.g-input {
  height: 4.4rem;
  box-sizing: border-box;
  padding-inline: 1.4rem;
  /* 16 Regular */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: #737283;
  border: solid 1px transparent;
  appearance: none;
  border-radius: 0.8rem;
  background: rgba(232, 231, 236, 0.5);
  width: 100%;
  font-family: inherit;
  outline: none;
}
.g-input:hover {
  background: rgba(232, 231, 236, 0.65);
}
.g-input:focus {
  background: rgba(232, 231, 236, 0.9);
  color: #0C0D2F;
}
.g-input::placeholder {
  color: #737283;
  opacity: 0.75;
}

.g-input.g-input--touched:invalid, .g-form--touched .g-input:invalid {
  border: solid 0.15rem #D90000;
  color: #D90000;
}
.g-input.g-input--touched:invalid::placeholder, .g-form--touched .g-input:invalid::placeholder {
  color: #D90000;
}

.g-input--textarea {
  min-height: 13rem;
  padding-block: 0.8rem;
}

.g-input--white {
  border: 1px solid #E8E7EC;
  background: none;
}
.g-input--white:hover {
  border: 1px solid #d6d5dc;
  background: none;
}
.g-input--white:focus {
  border: 1px solid #c5c4cd;
  background: none;
}

.g-input--with-icon {
  padding-left: 4.4rem;
}

.g-checkbox {
  appearance: none;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 0.6rem;
  border: 1px solid #D0D5DD;
  background: #FFF;
  position: relative;
  vertical-align: middle;
}
.g-checkbox::after {
  content: "";
  display: block;
  transition: all 0.2s;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  left: 0.2rem;
  top: 0.2rem;
  background: #A45EFF;
  border-radius: 0.4rem;
}
.g-checkbox:not(:checked)::after {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.7);
}
.g-checkbox:checked {
  border-color: #BBB;
}
.g-checkbox:focus, .g-checkbox:hover {
  border-color: #999;
}

.g-checkbox--touched:invalid, .g-form--touched.g-checkbox:invalid {
  border-color: #D90000;
  background: rgba(217, 0, 0, 0.3);
}

.g-select {
  height: 4.4rem;
  box-sizing: border-box;
  padding-inline: 1.4rem;
  /* 16 Regular */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: #737283;
  border: none;
  appearance: none;
  border-radius: 0.8rem;
  background: rgba(232, 231, 236, 0.5) url(../imgs/chevron-down-black.svg) no-repeat right 1rem center;
  background-size: 2rem auto;
  width: 100%;
  font-family: inherit;
  outline: none;
}
.g-select:hover {
  background-color: rgba(232, 231, 236, 0.8);
}
.g-select:focus {
  background-color: #e8e7ec;
  color: #0C0D2F;
}
.g-select::placeholder {
  color: #737283;
  opacity: 0.75;
}

.g-gradient {
  background: linear-gradient(110deg, #A45EFF 1%, #00B2FF 99%);
}

.g-gradient--semi {
  background: linear-gradient(110deg, #A45EFF 1%, #00B2FF 150%);
}

.g-gradient--blue2 {
  background: linear-gradient(110deg, #A45EFF 1%, #456EFF 150%);
}

.g-gradient:not(.g-gradient--bg) {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.g-badge-text {
  display: inline-block;
  border-radius: 10rem;
  padding: 0.4rem 1.2rem;
  color: var(--color);
  background-color: var(--color-bg);
}

.g-badge-text--square {
  border-radius: 1.2rem;
}

.g-badge-text--light {
  background-color: var(--color-light-bg);
}

.g-badge-text--sm {
  /* 24 Regular */
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.333;
  padding: 1.2rem 2.4rem;
  border-radius: 3.6rem;
}

.g-badge-text--wide {
  padding-inline: 3.6rem;
}

.g-badge-text--mega {
  padding: 2.4rem 6.4rem;
  /* 64 Medium */
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  border-radius: 10rem;
}

.g-badge-text--aligned {
  margin-left: -1.2rem;
}

.g-badge-text--neutral-color {
  color: #D4D4E5;
  background-color: #2E2C48;
}

.g-badge-text--gradient {
  background: linear-gradient(to right, #efe7fb 0%, #eae8fb 100%);
}

@media only screen and (max-width: 980px) {
  .g-badge-text--mega {
    font-size: 3.6rem;
    font-weight: 500;
    padding: 2.4rem 3.6rem;
  }
}
@media only screen and (max-width: 580px) {
  .g-badge-text--mega {
    font-size: 3.2rem;
    font-weight: 500;
    padding: 2rem 3rem;
  }

  .g-badge-text--wide {
    padding-inline: 1.6rem;
  }
}
.g-white-block {
  border-radius: 2.4rem;
  background: #FBFAFD;
  padding-block: 8.4rem;
  color: #0C0D2F;
}

.g-white-block--violet {
  background: linear-gradient(90deg, #A45EFF 2.44%, #456EFF 164%);
  color: white;
}

.g-panes__wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "pane";
  justify-content: flex-start;
  align-items: flex-start;
}

.g-panes__pane {
  grid-area: pane;
  transition: all 0.5s;
}

.g-panes__pane:not(.g-panes__pane--active) {
  opacity: 0;
  visibility: hidden;
}

.g-panes__pane--before {
  transform: translateX(2rem);
}

.g-panes__pane--after {
  transform: translateX(-2rem);
}

.g-panes__wrap--vertical .g-panes__pane--before {
  transform: translateY(-2rem);
}
.g-panes__wrap--vertical .g-panes__pane--after {
  transform: translateY(2rem);
}

.g-box-field {
  background: #2E2C48;
  padding: 3.2rem;
  color: var(--field-text-color, #D4D4E5);
  /* 24 Regular */
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.333;
  border-radius: 2.4rem;
}

.g-box-field__symbol {
  margin-bottom: 8.4rem;
  line-height: 1px;
}

.g-text-col {
  /* 20 Regular */
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  color: #D4D4E5;
  line-height: 1.6;
}

.g-text-col--left {
  width: 50rem;
  margin-right: auto;
  margin-left: 0;
}

.g-text-col--right {
  width: 50rem;
  margin-left: auto;
  margin-right: 0;
}

@media only screen and (max-width: 980px) {
  .g-text-col--right, .g-text-col--left {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
ul.g-numeric-facts {
  margin-top: 7rem;
  margin-bottom: 8.6rem;
  color: #D4D4E5;
  /* 16 Regular */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
}
ul.g-numeric-facts > li {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: solid 1px #2E2C48;
  line-height: 2;
}
ul.g-numeric-facts .g-numeric-facts__num {
  color: var(--color, #FDB022);
  /* 32 Medium */
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  display: inline-block;
  margin-inline: 1.6rem;
  vertical-align: middle;
}