// Keep in sync with src/lib/breakpoints.ts !!!

$containerWidth: 103.2rem;
$containerWidthNarrow: 50.4rem;
$containerWidthXxl: 156rem;

$paddingXs: 2.4rem;
$paddingFullWidth: 1.2rem;


// XS - mobiles. Mobile layout full-width

$breakpointXs: 580px;
$remRatioXs: 1;
$containerWidthSm: 52rem;


// SM - tablets. Mobile layout centered

$breakpointSm: 980px;
$remRatioSm: 0.85;


// MD - landscape tablets, small laptops. Desktop zooming layout. 875px container.

$breakpointMd: 1220px;
$breakpointMdVertical: 550px;
$remRatioMd: 1;

// LG - big landscape tablets, standard laptops. Desktop zooming layout. 1030px container.

$breakpointLg: 1600px;
$breakpointLgVertical: 800px;
$remRatioLg: 1.2;

// XL - big desktop screens, desktop layout, 1236px container
