@import 'colors';
@import 'breakpoints';
@import 'typography';

.g-h1 {
	@include Font64Medium;
	margin-block: 0;
}

.g-h2 {
	@include Font40Medium;
	margin-block: 0;
}

.g-h2-bigger {
	@include Font44Medium;
	line-height: 1.5;
	margin-block: 0;
}

.g-h3 {
	@include Font32Medium;
	margin-block: 0;
}

.g-h4 {
	@include Font24Medium;
	margin-block: 0;
}

.g-h4--bold {
	@include Font24Bold;
}

// Mobiles and Tablets

@media only screen and (max-width: $breakpointSm) {

	.g-h1 {
		@include Font36Medium;
	}

	.g-h2-bigger {
		@include Font32Medium;
		line-height: 1.5;
	}

	.g-h2 {
		@include Font28Medium;
	}

	.g-h3 {
		@include Font24Medium;
	}


}
