@import 'colors';
@import 'typography';

ul.g-numeric-facts {

	margin-top: 7rem;
	margin-bottom: 8.6rem;
	color: $colorDegis;
	@include Font16Regular;
	text-align: center;

	&>li {
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
		border-bottom: solid 1px $colorCarbonGreyBg;
		line-height: 2;
	}

	.g-numeric-facts__num {
		color: var(--color, #{$colorOrange});
		@include Font32Medium;
		display: inline-block;
		margin-inline: 1.6rem;
		vertical-align: middle;
	}

}
