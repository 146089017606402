@import 'breakpoints';

.g-panes__wrap {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-template-areas: 'pane';
	justify-content: flex-start;
	align-items: flex-start;
}

.g-panes__pane {
	grid-area: pane;
	transition: all 0.5s;
}

.g-panes__pane:not(.g-panes__pane--active) {
	opacity: 0;
	visibility: hidden;
}

.g-panes__pane--before {
	transform: translateX(2rem);
}

.g-panes__pane--after {
	transform: translateX(-2rem);
}


.g-panes__wrap--vertical {
	.g-panes__pane--before {
		transform: translateY(-2rem);
	}
	.g-panes__pane--after {
		transform: translateY(2rem);
	}
}
