@import 'colors';
@import 'breakpoints';

.c-about-us-text2 {
	margin-top: 16.4rem;
	margin-bottom: 12.4rem;
}

.about-us-text2__steps, ul.about-us-text2__steps {
	margin-block: 6.4rem;
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;

	&::after {
		position: absolute;
		content: '';
		height: 1px;
		left: 0;
		right: 0;
		background: linear-gradient(to right, $colorViolet 49.9%, $colorOrange 50%);
		z-index: 1;
	}
}

.about-us-text2__step, li.about-us-text2__step {
	position: relative;
	z-index: 2;

	&:not(:first-child) {
		border-left: solid 1.2rem $colorBlackPearl;
	}
	&:not(:last-child) {
		border-right: solid 1.2rem $colorBlackPearl;
	}
}

@media only screen and (max-width: $breakpointSm) {
	.c-about-us-text2 {
		margin-block: 7.6rem;
	}
}

