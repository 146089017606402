.c-text-block {
  color: #D4D4E5;
  /* 16 Regular */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  line-height: 1.75;
}

.text-block__text--center {
  text-align: center;
  list-style: inside;
}

.text-block__text h2, .text-block__text h3 {
  margin-top: 3em;
  margin-bottom: 1.5em;
}
.text-block__text .small {
  font-size: 2.4rem;
}
.text-block__text .white {
  color: #FBFAFD;
}
.text-block__text .text-block__image {
  max-width: 100%;
  margin-block: 8rem;
}
.text-block__text .text-block__image--round {
  border-radius: 2.4rem;
}

.text-block__text--size-lg {
  font-size: 3.2rem;
}

.text-block__text--size-md {
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  line-height: 1.6;
}

@media only screen and (max-width: 580px) {
  .text-block__text--center {
    text-align: left;
    list-style: outside;
  }

  .text-block__text--size-lg {
    font-size: 2.4rem;
  }

  .text-block__text h2, .text-block__text h3 {
    padding-left: 1em;
    text-indent: -1em;
  }
  .text-block__text .text-block__text-block__image {
    margin-block: 4rem;
  }
  .text-block__text .text-block__image--round {
    border-radius: 1.6rem;
  }
}