@import 'colors';
@import 'typography';
@import 'breakpoints';


.c-main-menu {
	box-sizing: border-box;
}

.main-menu__menu-bg {
	transition: background-color 0.2s;
}

.main-menu__box {
	display: grid;
	align-items: center;
	grid-template-rows: auto;
	grid-template-columns: 28rem 1fr 28rem;
}

.main-menu__logo, .main-menu__home, .main-menu__logo-space {
	line-height: 1px;
	width: 10rem;
	height: 2.4rem;
}

.main-menu__logo-space {
	position: relative;
}

.main-menu__home {
	height: 100%;
}

.main-menu__logo {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	transition: all 0.3s;
}

.main-menu__logo--dark {
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
}

.main-menu__logo--all-white {
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
}


.main-menu__expander-part {
	grid-column: -1 / span 1;
	justify-self: flex-end;
}

.main-menu__expander {
	gap: 1.6rem;
}

// we need to move the center part a bit - experimentally measured breakpoint
@media only screen and (max-width: 1040px) {

	.main-menu__box {
		grid-template-columns: auto auto auto;
	}

}

// Desktop
@media only screen and (min-width: $breakpointSm) {

	.c-main-menu {
		padding-top: $paddingXs;
		height: 6.4rem;
	}

	.main-menu__expander-part {
		display: none;
	}

	.c-main-menu .main-menu__menu-bg { // Overwrite ul.g-list-nelist
		background: $colorWhite;
		border-radius: 10rem;
		margin-left: auto;
		margin-right: auto;
		width: fit-content;
	}

	.c-main-menu .main-menu__menu-box { // Overwrite ul.g-list-nelist
		display: flex;
		padding-inline: 1.1rem;
		align-items: center;
	}

	.main-menu__menu-box--aux {
		gap: 2.4rem;
	}

	.main-menu__link {
		display: flex;
		color: $colorBlackPearl;
		@include Font12Medium;
		height: 4rem;
		align-items: center;
		justify-content: center;
		padding-inline: 2rem;
		transition: all 0.2s;

		&:hover, &:focus {
			text-decoration: none;
			color: $colorViolet;
		}

	}

	.main-menu__link--aux {
		color: $colorWhite;
		@include Font12Bold;
	}

	.main-menu__aux-part {
		justify-self: flex-end;
	}

	.main-menu__motto-part {
		display: none;
	}

	// Dark version

	.body-white-background {

		.main-menu__menu-bg {
			background: $colorBlackPearl;
		}

		.main-menu__link {
			color: $colorWhite;

			&:hover, &:focus {
				color: $colorViolet;
			}
		}

		.main-menu__link--aux {
			color: $colorBlackPearl;
		}

		.main-menu__logo--light {
			opacity: 0;
			visibility: hidden;
		}

		.main-menu__logo--dark {
			opacity: 1;
			visibility: visible;
		}

	}

	// Version for violet BG

	.body-violet-background {

		.main-menu__logo--light {
			opacity: 0;
			visibility: hidden;
		}

		.main-menu__logo--all-white {
			opacity: 1;
			visibility: visible;
		}

	}

}

// Tablet & Mobile layout
@media only screen and (max-width: $breakpointSm) {

	.c-main-menu {
		padding-top: $paddingXs;
		height: 4rem + $paddingXs;
		overflow: hidden;
		transition: all 0.4s;
	}

	.main-menu__box {
		display: grid;
		grid-template-columns: auto auto;
		grid-template-rows: 4rem auto 1fr auto;
		grid-template-areas: 'logo expander' 'motto motto' 'menu menu' 'aux aux';
		height: calc(100dvh - #{$paddingXs});
		padding-bottom: 6rem;
		box-sizing: border-box;
	}

	.main-menu__logo-space {
		grid-area: logo;
	}

	.main-menu__expander-part {
		grid-area: expander;
	}

	.main-menu__aux-part {
		grid-area: aux;
		margin-top: 3.6em;
		justify-self: center;
		text-align: center;
		color: $colorBlackPearl;
		transition: all 0.3s 0.3s;
	}

	.main-menu__menu-part {
		margin-top: 4rem;
		grid-area: menu;
		align-self: flex-start;
		transition: all 0.3s 0.2s;
	}

	.main-menu__motto-part {
		@include Font12Medium;
		color: $colorBlackPearl;
		grid-area: motto;
		margin-top: 2.4rem;
		transition: all 0.3s 0.1s;
	}

	.main-menu__link:not(.main-menu__link--aux) {
		display: block;
		padding-block: 2.4rem;
		@include Font24Regular;
		color: $colorBlackPearl;
	}

	.main-menu__menu-box--aux {
		display: flex;
		gap: 1.2rem;
		flex-direction: column-reverse;
	}

	.c-main-menu--open {

		background: $colorWhite;
		height: 100dvh;
		left: 0;
		right: 0;

		.main-menu__logo--dark {
			opacity: 1;
			visibility: visible;
		}

		.main-menu__logo--light {
			opacity: 0;
			visibility: hidden;
		}

		.main-menu__logo--all-white {
			opacity: 0;
			visibility: hidden;
		}

	}

	.body-white-background {

		.main-menu__logo--light {
			opacity: 0;
			visibility: hidden;
		}

		.main-menu__logo--dark {
			opacity: 1;
			visibility: visible;
		}

		.main-menu__logo--all-white {
			opacity: 0;
			visibility: hidden;
		}

	}

	.body-violet-background {

		.main-menu__logo--light {
			opacity: 0;
			visibility: hidden;
		}

		.main-menu__logo--dark {
			opacity: 0;
			visibility: hidden;
		}

		.main-menu__logo--all-white {
			opacity: 1;
			visibility: visible;
		}


	}

	.c-main-menu:not(.c-main-menu--open) {

		.main-menu__aux-part, .main-menu__menu-part, .main-menu__motto-part {
			opacity: 0;
			transform: translateY(-2rem);
		}

	}

}

// Tablet & Mobile layout - devices with low height
@media only screen and (max-width: $breakpointSm) and (max-height: 770px) {

	.main-menu__link:not(.main-menu__link--aux) {
		padding-block: 1.8rem;
	}

	.main-menu__menu-part {
		margin-top: 2.4rem;
	}

	.main-menu__aux-part {
		margin-top: 3rem;
	}

}

@media only screen and (max-width: $breakpointSm) and (max-height: 670px) {

	.main-menu__link:not(.main-menu__link--aux) {
		padding-block: 1.4rem;
	}

	.main-menu__menu-part {
		margin-top: 2rem;
	}

	.main-menu__aux-part {
		margin-top: 2rem;
	}

}


