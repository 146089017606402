.x-in-circle__colored {
  transition: all 0.3s;
  fill: var(--color, #FDB022);
}

.x-in-circle__x {
  width: var(--size, 100%);
  height: var(--size, 100%);
}

.x-in-circle__bg-colored {
  fill: var(--color-bg, rgba(255, 255, 255, 0.1));
}