@import 'colors';
@import 'breakpoints';
@import 'typography';

@mixin button-nebutton {
	border: none;
	background: none;
	box-shadow: none;
	border-radius: 0;
	appearance: none;
	cursor: pointer;
	font-family: inherit;
	font-size: inherit;
}

.g-button-nebutton {
	@include button-nebutton;
}


.g-button {
	@include button-nebutton;
	@include Font12Medium;
	font-weight: bold;
	height: 4rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding-inline: 3.2rem;
	box-sizing: border-box;
	transition: all 0.3s;
	border-radius: 10rem;

	&:hover {
		text-decoration: none;
	}
}

/* COLOR TYPES */

.g-button--outline {
	background: rgba(white, 0.01);
	backdrop-filter: blur(5px);
	border: 1px solid rgba(white, 0.16);

	&:hover, &:focus {
		background: $colorViolet;
		border-color: $colorViolet;
	}
}

.body-white-background .g-button--outline--violet-on-white-bg {
	background-color: $colorViolet;
	color: white;
	backdrop-filter: blur(6px);

	&:hover, &:focus, &.g-button--active {
		background-color: rgba($colorViolet, 0.8);
	}
}

.g-button--grey {
	background: rgba(156, 118, 232, 0.12);
	color: $colorWhite;
	backdrop-filter: blur(6px);

	&:hover, &:focus, &.g-button--active {
		background-color: rgba(251, 250, 253, 0.24);
	}
}

.body-white-background .g-button--grey--dark-on-white-bg {
	background-color: rgba($colorBlackPearl, 0.8);
	color: $colorWhite;
	backdrop-filter: blur(6px);

	&:hover, &:focus, &.g-button--active {
		background-color: $colorCarbon60;
	}
}


.g-button--violet {
	background-color: $colorViolet;
	color: white;
	backdrop-filter: blur(6px);

	&:hover, &:focus, &.g-button--active {
		background-color: rgba($colorViolet, 0.8);
	}
}

.g-button--green {
	background-color: $colorGreen;
	color: white;
}

.g-button--dark {
	background: $colorBlackPearl;
	color: $colorWhite;
	backdrop-filter: blur(6px);

	&:hover, &:focus, &.g-button--active {
		background-color: lighten($colorBlackPearl, 10%);
	}

}

.g-button--darken {
	background: rgba(8, 9, 39, 0.12);
	color: $colorWhite;

	&:hover, &:focus, &.g-button--active {
		background: rgba(8, 9, 39, 0.30);
	}
}

.g-button--lighten {
	background: rgba($colorWhite, 0.12);
	color: $colorWhite;

	&:hover, &:focus, &.g-button--active {
		background: rgba($colorWhite, 0.25);
	}
}

.g-button--text {
	background: none;
	@include Font14Bold;

	&:hover, &:focus, &.g-button--active {
		color: var(--color, #{$colorViolet});
	}
}

/* SIZES */

.g-button--sm {
	height: 3.6rem;
}

.g-button--lg {
	font-size: 1.6rem;
	height: 4.8rem;
}

.g-button--xl {
	font-size: 4rem;
	font-weight: 500;
	height: 10.4rem;
	padding-inline: 4.8rem;
}

.g-button--narrow {
	padding-inline: 2.2rem;
}

.g-button--block {
	width: 100%;
}

.g-button--icon {
	display: inline-flex;
	width: 4.8rem;
	height: 4.8rem;
	justify-content: center;
	padding: 0;
	margin: 0;
	align-items: center;
	border-radius: 10rem;
	background: rgba($colorWhite, 0.12);
	backdrop-filter: blur(4px);
}

/* DECORATIONS */

.g-button--no-pl {
	padding-left: 0.1rem;
}

.g-button--arrow {

	&::after {
		content: '';
		width: 2.4rem;
		height: 2.4rem;
		background: url('../imgs/chevron-right.svg') no-repeat center center;
		background-size: contain;
		margin-left: 1.2rem;
		margin-right: -0.8rem;
	}

	&:hover, &:focus, &.g-button--active {

		&::after, .g-button__arrow {
			animation: arrow-bounce infinite alternate 0.4s;
		}
	}

}

.g-button--xl.g-button--arrow {

	&::after {
		width: 4.8rem;
		height: 4.8rem;
		margin-left: 1.6rem;
		margin-right: -1.6rem;
		background-image: url('../imgs/arrow-right.svg');
	}

}

.g-button--arrow-left {

	&::before {
		content: '';
		width: 2.4rem;
		height: 2.4rem;
		background: url('../imgs/chevron-left.svg') no-repeat center center;
		background-size: contain;
		margin-right: 1.2rem;
		margin-left: -0.8rem;
	}

	&:hover, &:focus, &.g-button--active {

		&::before, .g-button__arrow {
			animation: arrow-bounce-left infinite alternate 0.4s;
		}
	}

}

.g-button--xl.g-button--arrow-left {

	&::before {
		width: 4.8rem;
		height: 4.8rem;
		margin-right: 1.6rem;
		margin-left: -1.6rem;
		background-image: url('../imgs/arrow-left.svg');
	}

}

.g-button--arrow--color {

	&::after {
		display: none;
	}

	.g-button__arrow {
		margin-left: 1.2rem;
		width: 2.4rem;
		height: 2.4rem;
		color: var(--color);
	}
}

.g-button--status-icon {
	background-size: 2.4rem auto;
	background-repeat: no-repeat;
	background-position: right 1.2rem center;
}

.g-button--sending {
	background-image: url(data:image/svg+xml;charset=utf-8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgc3R5bGU9Im1hcmdpbjogYXV0bzsgYmFja2dyb3VuZDogbm9uZTsgZGlzcGxheTogYmxvY2s7IHNoYXBlLXJlbmRlcmluZzogYXV0bzsiIHdpZHRoPSIyMDBweCIgaGVpZ2h0PSIyMDBweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIj4NCjxjaXJjbGUgY3g9IjUwIiBjeT0iNTAiIHI9IjQxIiBzdHJva2Utd2lkdGg9IjE0IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS1kYXNoYXJyYXk9IjY0LjQwMjY0OTM5ODU5MDc1IDY0LjQwMjY0OTM5ODU5MDc1IiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiPg0KICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGR1cj0iMS41MTUxNTE1MTUxNTE1MTUxcyIga2V5VGltZXM9IjA7MSIgdmFsdWVzPSIwIDUwIDUwOzM2MCA1MCA1MCI+PC9hbmltYXRlVHJhbnNmb3JtPg0KPC9jaXJjbGU+DQo8L3N2Zz4=);
}

.g-button--success {
	background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiPjxwYXRoIGQ9Ik0zMiwyQzE1LjQzMSwyLDIsMTUuNDMyLDIsMzJjMCwxNi41NjgsMTMuNDMyLDMwLDMwLDMwYzE2LjU2OCwwLDMwLTEzLjQzMiwzMC0zMEM2MiwxNS40MzIsNDguNTY4LDIsMzIsMnogTTI1LjAyNSw1MA0KCWwtMC4wMi0wLjAyTDI0Ljk4OCw1MEwxMSwzNS42bDcuMDI5LTcuMTY0bDYuOTc3LDcuMTg0bDIxLTIxLjYxOUw1MywyMS4xOTlMMjUuMDI1LDUweiIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}

@keyframes arrow-bounce {

	from {
		transform: none;
	}

	to {
		transform: translateX(1rem);
	}

}

@keyframes arrow-bounce-left {

	from {
		transform: none;
	}

	to {
		transform: translateX(-1rem);
	}

}


@media only screen and (max-width: $breakpointSm) {

	.g-button--xl-small-sm {
		font-size: 3.2rem;
		padding-inline: 3.2rem;
	}

	.g-button--xl-small-sm.g-button--arrow {
		&::after {
			width: 4rem;
			height: 4rem;
		}
	}

}
