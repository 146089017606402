.c-home-schema {
  margin-top: 16.4rem;
  margin-bottom: 16.4rem;
}

.home-schema__heading {
  text-align: center;
  max-width: 38ch;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6.4rem;
}

.home-schema__arrow {
  margin-bottom: 6.4rem;
  text-align: center;
}
.home-schema__arrow::after {
  content: "";
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  background: url(../imgs/arrow-down.svg) center center/cover;
}

.home-schema__lines {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2.4rem;
  text-align: center;
}

.home-schema__x {
  width: 4.8rem;
  position: absolute;
  left: calc(50% - 2.4rem);
  top: 0;
}

.home-schema__lines-schema {
  width: 66%;
  height: auto;
  fill: none;
}

.home-schema__basic-lines path {
  stroke: #2E2C48;
}

.home-schema__line-path {
  stroke: var(--color);
  transition: all 0.3s;
  stroke-dasharray: var(--line-len);
  stroke-dashoffset: var(--line-len);
}

.home-schema__line-path--active {
  stroke-dashoffset: 0;
}

.home-schema__panel {
  padding-top: 5.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "left right" "button right";
  column-gap: 6.4rem;
  row-gap: 3.4rem;
}

.home-schema__col-left {
  grid-area: left;
}

.home-schema__col-right {
  grid-area: right;
}

.home-schema__col-button {
  grid-area: button;
}

.home-schema__col-heading {
  /* 32 Medium */
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 2.8rem;
}

.home-schema__text {
  /* 20 Regular */
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  color: #D4D4E5;
}

.home-schema__more-button {
  color: var(--color);
}

.home-schema__col-image {
  width: 100%;
  height: auto;
}

.home-schema__num-block {
  padding-bottom: 1.6rem;
  padding-top: 1.2rem;
  border-bottom: solid 1px #2E2C48;
}

.home-schema__num-label {
  /* 16 Regular */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: #D4D4E5;
}

.home-schema__num {
  /* 32 Medium */
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  color: #FDB022;
  margin-top: 0.8rem;
}

@media only screen and (max-width: 580px) {
  .c-home-schema {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .home-schema__heading {
    /* 26 Medium */
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
  }

  .home-schema__lines {
    display: none;
  }

  .home-schema__panel {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: "left" "right" "button";
    row-gap: 4.8rem;
  }
}