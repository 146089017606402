@import 'breakpoints';

html, body {
	font-size: 16px;
}


/* REM size */


// Mobile and small tablet versions
@media only screen and (max-width: $breakpointSm) {

	html, body {
		font-size: (10px * $remRatioXs);
		--rem-ratio: #{$remRatioXs};
		--layout-type: tablet;
	}

}

// Mobile only versions
@media only screen and (max-width: $breakpointXs) {

	html, body {
		--layout-type: mobile;
	}

}


// 85% version
@media only screen and (min-width: $breakpointSm) {

	html, body {
		font-size: (10px * $remRatioSm);
		--rem-ratio: #{$remRatioSm};
		--layout-type: desktop;
	}

}


// 100%

@media only screen and (min-width: $breakpointMd) and (min-height: $breakpointMdVertical) {

	html, body {
		font-size: (10px * $remRatioMd);
		--rem-ratio: #{$remRatioMd};
		--layout-type: desktop;
	}

}

// 120% version

@media only screen and (min-width: $breakpointLg) and (min-height: $breakpointLgVertical) {

	html, body {
		font-size: (10px * $remRatioLg);
		--rem-ratio: #{$remRatioLg};
		--layout-type: desktop;
	}

}
