@import 'typography';
@import 'breakpoints';
@import 'colors';

.g-text-col {

	@include Font20Regular;
	color: $colorDegis;
	line-height: 1.6;

}

.g-text-col--left {
	width: 50rem;
	margin-right: auto;
	margin-left: 0;
}

.g-text-col--right {
	width: 50rem;
	margin-left: auto;
	margin-right: 0;
}


@media only screen and (max-width: $breakpointSm) {

	.g-text-col--right, .g-text-col--left {
		width: auto;
		margin-left: auto;
		margin-right: auto;
	}

}
