.c-home-hero {
  height: 86.4rem;
  position: relative;
  overflow: hidden;
}

.home-hero__bg {
  position: absolute;
  inset: 0;
}

.home-hero__bg-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.home-hero__body {
  position: relative;
  z-index: 1;
  height: 100%;
  box-sizing: border-box;
  padding-top: 18.8rem;
}

.home-hero__h1-part {
  display: block;
}

.home-hero__subheading {
  margin-top: 1.2rem;
  color: #E8E7EC;
  /* 16 Regular */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
}

.home-hero__button-space {
  margin-top: 4.4rem;
}

.home-hero__gradient-text {
  display: inline-block;
}

/* Animation stuff */
@media only screen and (min-width: 980px) {
  .home-hero__body.state-animated .home-hero__word {
    transition: all 0.6s;
  }

  .home-hero__body.state-animated .home-hero__word-rest {
    transition: all 0.6s calc(var(--word-index, 0) * 0.1s);
  }

  .home-hero__body.state-animated .home-hero__h1-part-1 {
    transition: all 0.8s;
  }

  .home-hero__body.state-animated .home-hero__h1-part-2 {
    transition: all 0.6s;
  }

  .home-hero__body.state-animated .home-hero__subheading {
    transition: all 0.6s 0.2s;
  }

  .home-hero__body.state-animated .home-hero__button-space {
    transition: all 0.6s 0.2s;
  }

  .home-hero__bg.state-animated {
    transition: all 0.9s;
  }

  .home-hero__body.state-running .home-hero__h1 {
    line-height: 1.15;
  }

  .home-hero__body.state-running .home-hero__h1-part-1 {
    padding-left: 45rem;
    transform: scale(1.6);
  }

  .home-hero__body.state-running .home-hero__word {
    opacity: 0;
    overflow: hidden;
    width: var(--initial-width, 0.5em);
    display: inline-block;
    margin-right: -0.15em;
  }

  .home-hero__body.state-running .home-hero__word-rest {
    opacity: 0;
  }

  .home-hero__body.state-running .home-hero__h1-part-2 {
    opacity: 0;
    transform: translateY(-2rem);
  }

  .home-hero__body.state-running .home-hero__subheading {
    opacity: 0;
    transform: translateY(-2.5rem);
  }

  .home-hero__body.state-running .home-hero__button-space {
    opacity: 0;
    transform: translateY(-3rem);
  }

  .home-hero__bg.state-running {
    opacity: 0;
    transform: scale(1.04);
  }

  .home-hero__body.state-step-1 .home-hero__word {
    opacity: 1;
    transition-delay: calc(var(--word-index, 0) * 0.05s);
  }

  .home-hero__bg.state-running.state-step-1 {
    opacity: 1;
    transform: none;
  }

  .home-hero__body.state-step-2 .home-hero__word {
    width: var(--target-w, 2em);
    margin-right: -0.1em;
    transition-delay: 0s;
    transition-duration: 0.9s;
  }

  .home-hero__body.state-step-2 .home-hero__h1-part-1 {
    padding-left: 0;
    transform: none;
  }

  .home-hero__body.state-step-3 .home-hero__word-rest {
    opacity: 1;
    transition-duration: 1s;
  }

  .home-hero__body.state-step-4 .home-hero__h1-part-2 {
    opacity: 1;
    transform: none;
  }

  .home-hero__body.state-step-4 .home-hero__subheading {
    opacity: 1;
    transform: none;
  }

  .home-hero__body.state-step-4 .home-hero__button-space {
    opacity: 1;
    transform: none;
  }
}
@media only screen and (min-width: 580px) and (max-width: 980px) {
  .c-home-hero {
    max-height: 100vh;
  }

  .home-hero__h1 {
    font-size: 4.8rem;
  }
}
@media only screen and (max-width: 580px) {
  .c-home-hero {
    height: 58rem;
  }

  .home-hero__body {
    display: flex;
    flex-direction: column;
    padding-top: 13.2rem;
  }

  .home-hero__h1 {
    max-width: 12ch;
  }

  .home-hero__h1-part--white {
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 4.4rem;
  }

  .home-hero__h1-part--color {
    font-size: 4.4rem;
    line-height: 6.4rem;
  }

  .home-hero__subheading {
    justify-self: flex-end;
    max-width: 18ch;
    margin-top: 9.6rem;
  }

  .home-hero__button-space {
    margin-top: 2.4rem;
  }
}