@import "colors";
@import "breakpoints";

.c-insights-list {
	margin-top: 8.4rem;
}

.insights-list__switcher {
	text-align: center;
	margin-bottom: 4.8rem;
}

.insights-list__insights {
	display: grid;
	grid-template-columns: 50.4rem 50.4rem;
	grid-auto-rows: auto;
	column-gap: 2.4rem;
	row-gap: 8.4rem;
}

.insights-list__insight {
	transition: opacity 0.2s, visibility 0.2s;
}

.insights-list__insight--fade {
	opacity: 0;
	visibility: hidden;
}


// Tablets
@media only screen and (min-width: $breakpointXs) and (max-width: $breakpointSm) {

	.insights-list__insights {
		grid-template-columns: 1fr 1fr;
	}

}

// Mobiles
@media only screen and (max-width: $breakpointXs) {

	.insights-list__insights-list {
		margin-top: 7.6rem;
	}

	.insights-list__insights {
		grid-template-columns: 1fr;
		row-gap: 5.6rem;
	}

}
