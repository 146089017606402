a {
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}


