@import 'breakpoints';
@import 'typography';

.c-page-hero {
	padding-block: $paddingFullWidth;
	margin-bottom: 13.6rem;
}

.c-page-hero--mb0 {
	margin-bottom: 0;
}

.page-hero__body {
	padding-top: 13rem;
	padding-bottom: 13rem;
	text-align: center;
	position: relative;
}

.page-hero__body--with-subheading {
	padding-bottom: 11.4rem;
	padding-top: 11.4rem;
}

.page-hero__body--left {
	text-align: left;
}

//.heading {
//	white-space: pre-line;
//}

.page-hero__subheading {
	margin-top: 2.4rem;
	@include Font16Regular;
}

.page-hero__minitext {
	@include Font16Medium;
	margin-bottom: 1.2rem;
}

.page-hero__arrow {
	width: 4.4rem;
	height: 4.4rem;
	position: absolute;
	bottom: 4.6rem;
	padding: 1rem;
	box-sizing: border-box;
	left: calc(50% - 2.2rem);
	border: none;
	appearance: none;
	cursor: pointer;
	background: none;

	&::after {
		content: '';
		display: inline-block;
		width: 2.4rem;
		height: 2.4rem;
		background: url('../imgs/arrow-down-black.svg') center center no-repeat;
		background-size: contain;
		transition: all 0.2s;
	}


	&:hover, &:focus {
		&::after {
			transform: scale(1.5);
			animation: none;
		}
	}
}

.page-hero__arrow--bouncing {
	animation: bouncing-arrow 0.6s infinite alternate;

	&:hover, &:focus {
		animation-play-state: paused;
	}
}

@keyframes bouncing-arrow {
	from {
		transform: none;
	}
	to {
		transform: translateY(1rem);
	}
}


// Tablets and mobiles

@media only screen and (max-width: $breakpointSm) {
	.c-page-hero {
		margin-bottom: 7.6rem;
	}
	.page-hero__body {
		padding-top: 13rem;
		padding-bottom: 12.4rem;
	}

}

// Mobiles

@media only screen and (max-width: $breakpointXs) {

	.page-hero__heading {
		text-align: left;
	}

	.page-hero__subheading {
		text-align: left;
	}

	.page-hero__arrow {
		left: 1.4rem;
		bottom: 1.4rem;
	}
}
