.tabs__tabs-box {
  display: flex;
}

.tabs__tab-item {
  flex: 1 1 0;
}

.tabs__tab-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.4rem;
  /* 12 Medium */
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
  width: 100%;
  color: var(--base-tab-color, #D4D4E5);
  border-bottom: solid 1px #2E2C48;
  transition: all 0.3s;
  cursor: pointer;
}
.tabs__tab-button:hover {
  background: var(--hover-tab-bg, rgba(46, 44, 72, 0.3));
}

.tabs__tab-button--active {
  border-bottom-color: var(--color);
  color: var(--color);
}