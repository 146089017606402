@import 'colors';

.g-color-orange {
	--color: #{$colorOrange};
	--color-bg: #{$colorOrangeBg};
	--color-light-bg: #{$colorOrangeLightBg};
}

.g-color-teal {
	--color: #{$colorTeal};
	--color-bg: #{$colorTealBg};
	--color-light-bg: #{$colorTealLightBg};
}

.g-color-violet {
	--color: #{$colorViolet};
	--color-bg: #{$colorVioletBg};
	--color-light-bg: #{$colorVioletLightBg};
}

.g-color-green {
	--color: #{$colorLightGreen};
	--color-bg: #{$colorLightGreenBg};
	--color-light-bg: #{$colorLightGreenBg};
}
