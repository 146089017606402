@import 'colors';
@import 'breakpoints';

.login-page__body {
	height: 100dvh;
	display: flex;
}

.login-page__left {
	flex: 0 0 calc(50% + 6rem);
	position: relative;
	background: #57443C;
}

.login-page__bg {
	position: absolute;
	z-index: 1;
	inset: 0;
	transition: all 1s;
}

.login-page__bg-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: right center;
}

.login-page__right {
	flex: 1 1 0;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $colorCarbon60;
	position: relative;
	z-index: 1;
}

.login-page__form {
	width: 40rem;
	transition: all 0.7s 0.2s;
}

.login-page__back {
	position: absolute;
	top: 3.6rem;
	left: 6rem;
	z-index: 2;
}
.login-page__back-button {

	&:hover, &:focus {
		background: rgba(white, 0.25);

		.login-page__button-icon {
			animation: arrow-bounce 0.5s infinite alternate;
		}
	}
}
.login-page__button-icon {
	width: 2.4rem;
	height: 2.4rem;
	display: inline-block;
	background: url('../imgs/arrow-left.svg') no-repeat center center;
	background-size: contain;
}


.login-page__headings {
	position: absolute;
	left: 6rem;
	bottom: 3.6rem;
	z-index: 2;
}

.login-page__heading {
	color: $colorWhite;
	font-size: 7vw;
	font-weight: 500;
	margin: 0 0 1.5vw;

	transition: all 0.8s 0.5s;
}
.login-page__subheading {
	color: rgba($colorCarbonGrey, 0.64);
	font-size: 1.93vw;
	font-weight: 400;
	margin: 0;

	transition: all 0.8s 0.7s;
}


.login-page__logo {
	position: absolute;
	right: 6rem;
	top: 3.6rem;
}

.login-page__logo-img {

}

.login-page__body:not(.login-page__body--entered) {

	.login-page__bg {
		opacity: 0;
		transform: scale(1.1);
	}

	.login-page__heading {
		opacity: 0;
		transform: translateY(2rem);
	}
	.login-page__subheading {
		opacity: 0;
		transform: translateY(1.5rem);
	}

	.login-page__form {
		opacity: 0;
	}

}

@keyframes arrow-bounce {
	from {
		transform: none;
	}
	to {
		transform: translateX(-0.5rem);
	}
}

@media only screen and (max-width: $breakpointSm) {

	.login-page__body {
		display: block;
	}

	.login-page__left {
		display: none;
	}

	.login-page__right {
		height: 100%;
	}

	.login-page__back {
		left: 2.4rem;
		top: 2.4rem;
	}

	.login-page__logo {
		right: 2.4rem;
		top: 3.6rem;
	}

	.login-page__form {
		width: auto;
		max-width: 50rem;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}

	.login-page__back-button {
		background-color: rgba(12, 13, 47, 0.08);

		&:hover, &:focus {
			background: rgba(12, 13, 47, 0.15);

			.login-page__button-icon {
				animation: arrow-bounce 0.5s infinite alternate;
			}
		}
	}

	.login-page__button-icon {
		background-image: url('../imgs/arrow-left-black.svg');
	}

}
