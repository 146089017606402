.c-footer {
  margin-top: 20rem;
}

.footer__line {
  border-bottom: solid 1px rgba(255, 255, 255, 0.12);
}

.footer__box {
  display: grid;
  grid-template-columns: 42rem repeat(4, auto);
  grid-template-rows: auto auto;
  grid-template-areas: "claim m1 m2 m3 m4" "certs certs certs certs certs";
  justify-content: flex-start;
  column-gap: 9.6rem;
  row-gap: 1.2rem;
  padding-top: 6.8rem;
  padding-bottom: 4.8rem;
}

.footer__claim-part {
  grid-area: claim;
  /* 24 Regular */
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.333;
}

.footer__col--1 {
  grid-area: m1;
}

.footer__col--2 {
  grid-area: m2;
}

.footer__col--3 {
  grid-area: m3;
}

.footer__col--4 {
  grid-area: m4;
}

.footer__col-heading {
  /* 14 Medium */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
  margin-bottom: 1.8rem;
  display: block;
}

.footer__link {
  /* 16 Regular */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  display: block;
  padding: 0.6rem 0;
  color: #D4D4E5;
  transition: all 0.2s;
}
.footer__link:hover {
  text-decoration: none;
  color: #A45EFF;
}

.footer__certs {
  /* 12 Medium */
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.333;
  display: flex;
  align-items: center;
  gap: 2.4rem;
  grid-column: 1/span 5;
  grid-area: certs;
}

.footer__cert {
  vertical-align: middle;
  margin-right: 0.8rem;
}

.footer__cert--iso {
  width: 4.4rem;
  height: auto;
}

.footer__cert--ch {
  width: 2.4rem;
  height: auto;
}

@media only screen and (max-width: 980px) {
  .c-footer {
    margin-top: 14rem;
  }

  .footer__box {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 2.4rem;
    row-gap: 4rem;
    padding-top: 4.8rem;
    grid-template-areas: "claim claim" "m4 m1" "m2 m3" "certs certs";
    padding-bottom: 3.2rem;
  }

  .footer__claim-part {
    /* 20 Regular */
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    grid-column: 1/span 2;
  }

  .footer__certs {
    grid-column: 1/span 2;
    margin-top: 3rem;
  }
}