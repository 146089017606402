.home-brm-platform__above {
  margin-bottom: 2.8rem;
  /* 14 Medium */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
  text-align: center;
  --color: #A45EFF;
  --color-bg: rgba(164, 94, 255, 0.1);
}

.home-brm-platform__heading {
  margin-bottom: 5rem;
  text-align: center;
}

.home-brm-platform__tabs {
  margin-bottom: 3.2rem;
  --base-tab-color: #737283;
  --hover-tab-bg: rgba(115, 114, 131, 0.1);
}

.home-brm-platform__image {
  height: auto;
  width: 100%;
}

.home-brm-platform__swipe-helper {
  display: none;
  /* 14 Medium */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
  color: #737283;
}

@media only screen and (max-width: 580px) {
  .home-brm-platform__width-base-wrap {
    overflow: auto;
  }

  .home-brm-platform__width-base {
    width: 60rem;
  }

  .home-brm-platform__swipe-helper {
    margin-top: 2.4rem;
    display: block;
  }
}