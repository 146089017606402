@import 'colors';
@import 'typography';
@import 'breakpoints';

.c-insight {

}

.insight__image-part {
	margin-bottom: 2.4rem;
	border-radius: 2.4rem;
	height: 28.2rem;
	overflow: hidden;
}

.insight__image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
	transition: all 0.3s;
}

.insight__name {
	@include Font32Medium;
	margin: 0 0 2.4rem;
}

.insight__label {
	@include Font12Bold;
	display: inline-block;
}

.insight__label--color {
	color: var(--color);
}


.c-insight:hover {
	text-decoration: none;

	.insight__image {
		transform: scale(1.07);
	}

}

// Tablets

@media only screen and (min-width: $breakpointXs) and (max-width: $breakpointSm) {

	.insight__image-part {
		height: auto;
	}

	.insight__name {
		@include Font26Medium;
	}

}

// Mobiles

@media only screen and (max-width: $breakpointXs) {
	.insight__image-part {
		height: auto;
	}
	.insight__name {
		margin-bottom: 1.2rem;
	}
}
