@import 'colors';

.g-gradient {
	background: linear-gradient(110deg, $colorViolet 1%, $colorBlue 99%);
}

.g-gradient--semi {
	background: linear-gradient(110deg, $colorViolet 1%, $colorBlue 150%);
}

.g-gradient--blue2 {
	background: linear-gradient(110deg, $colorViolet 1%, $colorBlue2 150%);
}

.g-gradient:not(.g-gradient--bg) {
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
