@import 'colors';
@import 'typography';
@import 'breakpoints';

.g-badge-text {
	display: inline-block;
	border-radius: 10rem;
	padding: 0.4rem 1.2rem;

	color: var(--color);
	background-color: var(--color-bg);
}

.g-badge-text--square {
	border-radius: 1.2rem;
}

.g-badge-text--light {
	background-color: var(--color-light-bg);
}

.g-badge-text--sm {
	@include Font24Medium;
	padding: 1.2rem 2.4rem;
	border-radius: 3.6rem;
}

.g-badge-text--wide {
	padding-inline: 3.6rem;
}

.g-badge-text--mega {
	padding: 2.4rem 6.4rem;
	@include Font64Medium;
	border-radius: 10rem;
}

.g-badge-text--aligned {
	margin-left: -1.2rem;
}

.g-badge-text--neutral-color {
	color: $colorDegis;
	background-color: $colorCarbonGreyBg;
}

.g-badge-text--gradient {
	background: linear-gradient(to right, #efe7fb 0%, #eae8fb 100%);
}

// Mobile, tablet
@media only screen and (max-width: $breakpointSm) {
	.g-badge-text--mega {
		font-size: 3.6rem;
		font-weight: 500;
		padding: 2.4rem 3.6rem;
	}
}

// Mobile

@media only screen and (max-width: $breakpointXs) {
	.g-badge-text--mega {
		font-size: 3.2rem;
		font-weight: 500;
		padding: 2rem 3rem;
	}

	.g-badge-text--wide {
		padding-inline: 1.6rem;
	}
}
