@import 'typography';
@import 'colors';
@import 'breakpoints';

.c-home-schema {
	margin-top: 16.4rem;
	margin-bottom: 16.4rem;
}

.home-schema__heading {
	text-align: center;
	max-width: 38ch;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 6.4rem;
}

.home-schema__arrow {
	margin-bottom: 6.4rem;
	text-align: center;

	&::after {
		content: '';
		display: inline-block;
		width: 2.4rem;
		height: 2.4rem;
		background: url(../imgs/arrow-down.svg) center center / cover;
	}
}

.home-schema__lines {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	padding-top: 2.4rem;
	text-align: center;
}

.home-schema__x {
	width: 4.8rem;
	position: absolute;
	left: calc(50% - 2.4rem);
	top: 0;
}

.home-schema__lines-schema {
	width: 66%;
	height: auto;
	fill: none;
}

.home-schema__basic-lines path {
	stroke: $colorCarbonGreyBg;
}

.home-schema__line-path  {
	stroke: var(--color);
	transition: all 0.3s;
	stroke-dasharray: var(--line-len);
	stroke-dashoffset: var(--line-len);
}

.home-schema__line-path--active {
	stroke-dashoffset: 0;
}

.home-schema__panel {
	padding-top: 5.6rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas: 'left right' 'button right';
	column-gap: 6.4rem;
	row-gap: 3.4rem;
}



.home-schema__col-left {
	grid-area: left;
}

.home-schema__col-right {
	grid-area: right;
}

.home-schema__col-button {
	grid-area: button;
}

.home-schema__col-heading {
	@include Font32Medium;
	margin-bottom: 2.8rem;
}

.home-schema__text {
	@include Font20Regular;
	color: $colorDegis;
}

.home-schema__more-button {
	color: var(--color);
}

.home-schema__col-image {
	width: 100%;
	height: auto;
}

.home-schema__num-block {
	padding-bottom: 1.6rem;
	padding-top: 1.2rem;
	border-bottom: solid 1px $colorCarbonGreyBg;
}

.home-schema__num-label {
	@include Font16Regular;
	color: $colorDegis;
}

.home-schema__num {
	@include Font32Medium;
	color: $colorOrange;
	margin-top: 0.8rem;
}

@media only screen and (max-width: $breakpointXs) {


	.c-home-schema {
		margin-top: 9rem;
		margin-bottom: 9rem;
	}

	.home-schema__heading {
		@include Font26Medium;
	}

	.home-schema__lines {
		display: none;
	}

	.home-schema__panel {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto 1fr;
		grid-template-areas: 'left' 'right' 'button';
		row-gap: 4.8rem;
	}



}

