@import 'typography';
@import 'breakpoints';
@import 'colors';

.home-brm-platform__above {
	margin-bottom: 2.8rem;
	@include Font14Bold;
	text-align: center;
	--color: #{$colorViolet};
	--color-bg: #{rgba($colorViolet, 0.1)};
}

.home-brm-platform__heading {
	margin-bottom: 5rem;
	text-align: center;
}

.home-brm-platform__tabs {
	margin-bottom: 3.2rem;
	--base-tab-color: #{$colorCarbon60};
	--hover-tab-bg: #{rgba($colorCarbon60, 0.1)};
}

.home-brm-platform__image {
	height: auto;
	width: 100%;
}

.home-brm-platform__swipe-helper {
	display: none;
	@include Font14Bold;
	color: $colorCarbon60;
}

@media only screen and (max-width: $breakpointXs) {

	.home-brm-platform__width-base-wrap {
		overflow: auto;
	}

	.home-brm-platform__width-base {
		width: 60rem;
	}

	.home-brm-platform__swipe-helper {
		margin-top: 2.4rem;
		display: block;
	}

}
