
@import 'colors';
@import 'typography';
@import 'breakpoints';

.c-solution-insights {
	margin-top: 16rem;
	margin-bottom: 18.4rem;
}

.solution-insights__above {
	margin-bottom: 2.2rem;
	font-size: 1.6rem;
}

.solution-insights__box {
	margin-top: 16.4rem;
	position: relative;
	display: flex;
	justify-content: space-between;
}

.solution-insights__sticky-wrap {
	position: relative;
	flex: 0 0 48rem;
	padding-bottom: 13rem;
}

.solution-insights__sticky-part {
	position: sticky;
	top: 18.5rem;
	left: 0;
}


.solution-insights__menu-item {
	@include Font32Medium;
	display: block;
	position: relative;
	padding: 2.5rem 5rem 2.5rem 7.6rem;
	color: rgba($colorDegis, 0.25);
	transition: all 0.2s;

	&:hover {
		text-decoration: none;
		color: rgba($colorDegis, 0.5);
	}

}

.solution-insights__numero {
	font-size: 0.8rem;
	position: absolute;
	left: 4.8rem;
	width: 1.4rem;
	text-align: right;
	top: 3.2rem;
	font-weight: bold;
	color: rgba($colorViolet, 0.5);
	transition: all 0.2s;
}

.solution-insights__menu-item--active {

	color: $colorDegis;

	&:hover {
		color: $colorDegis;
	}

	.solution-insights__numero {
		color: $colorViolet;
	}
}

.solution-insights__content-part {
	flex: 0 0 50.4rem;
}

.solution-insights__content {
	margin-bottom: 15rem;
}

.solution-insights__text-content {
	width: 50.4rem;
	margin-right: 0;
	margin-left: auto;
	margin-bottom: 8.4rem;
	transition: all 0.5s;
}

.solution-insights__image {
	transition: all 0.5s;
	text-align: right;
}

.solution-insights__image-img {
	max-width: 100%;
	width: 100%;
	height: auto;
}

.solution-insights__content-heading {
	color: $colorWhite;
	margin-bottom: 3.2rem;
}

.solution-insights__deco {
	position: absolute;
	left: 0;
	top: -14rem;
}

.solution-insights__x {
	position: absolute;
	transition: top 0.3s;
	left: -1.5rem;
	top: 0;
}

.solution-insights__deco-svg {
	width: 48rem;
}

// path length = 920
// Dash offset steps: 290px, 195px, 100px, 5px
.solution-insights__deco-line {
	stroke: $colorViolet;
	stroke-dashoffset: 0;
	stroke-dasharray: 920px 920px;
	transition: stroke-dashoffset 0.3s;
}

.solution-insights__deco--state-0 {
	.solution-insights__deco-line {
		stroke-dashoffset: 290px;
	}

	.solution-insights__x {
		top: 17rem;
	}
}

.solution-insights__deco--state-1 {
	.solution-insights__deco-line {
		stroke-dashoffset: 195px;
	}

	.solution-insights__x {
		top: 26.4rem;
	}
}

.solution-insights__deco--state-2 {
	.solution-insights__deco-line {
		stroke-dashoffset: 100px;
	}

	.solution-insights__x {
		top: 35.7rem
	}
}

.solution-insights__deco--state-3 {
	.solution-insights__deco-line {
		stroke-dashoffset: 5px;
	}

	.solution-insights__x {
		top: 45.3rem
	}
}

.solution-insights__swipe-help {
	margin-top: 2.4rem;
	@include Font14Bold;
}


// Desktop
@media only screen and (min-width: $breakpointSm) {

	.solution-insights__swipe-help {
		display: none;
	}

}

// Mobile + tablet
@media only screen and (max-width: $breakpointSm) {

	.c-solution-insights {
		margin-top: 7.6rem;
		margin-bottom: 12.4rem;
	}

	.solution-insights__box {
		display: block;
		margin-top: 3.2rem;
		padding-left: 0;
	}

	.solution-insights__menu, .solution-insights__sticky-wrap, .solution-insights__sticky-part {
		position: static;
	}

	.solution-insights__sticky-wrap {
		padding-bottom: 1.6rem;
	}

	.solution-insights__menu-item {
		@include Font16Medium;
		padding-left: 2.8rem;
		padding-block: 0.8rem;
	}

	.solution-insights__numero {
		left: 0;
		top: 1rem;
	}

	.solution-insights__content-part {
		margin-top: 3.2rem;
	}

	.solution-insights__text-content {
		width: auto;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 4.8rem;
		min-height: 0;
	}


	.solution-insights__deco {
		display: none;
	}

	.solution-insights__image-img {
		width: 100%;
	}

	.solution-insights__content {
		margin-bottom: 8.4rem;
	}


}

// Tablet only
@media only screen and (min-width: $breakpointXs) and (max-width: $breakpointSm) {

	.solution-insights__swipe-help {
		display: none;
	}

}

// Mobile only

@media only screen and (max-width: $breakpointXs) {

	.solution-insights__content {
		width: calc(100vw - 2 * #{$paddingXs});
	}

	.solution-insights__text-content {

	}

	.solution-insights__image {
		margin-right: -1 * $paddingXs;
		margin-left: -1 * $paddingXs;
		width: calc(100% + 2 * #{$paddingXs});
		overflow: auto;
		padding-left: $paddingXs;
		padding-right: $paddingXs;
		box-sizing: border-box;
	}

	.solution-insights__image-img {
		width: auto;
		max-width: none;
		height: auto;
	}

	.solution-insights__swipe-help {
		display: block;
	}


}
