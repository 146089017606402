@import 'typography';
@import 'breakpoints';
@import 'colors';

.c-home-subheading {
	margin-top: 18rem;
	margin-bottom: 11.2rem;
}

.home-subheading__heading {
	@include Font40Medium;
}

.home-subheading__see {
	margin-top: 8.4rem;
	@include Font32Medium;
}

.home-subheading__text {

	margin-top: 4rem;
	display: flex;
	gap: 2.4rem;
	color: $colorDegis;
	@include Font16Regular;

}

.home-subheading__col {
	flex: 1 1 0;
}

@media only screen and (max-width: $breakpointXs) {

	.c-home-subheading {
		margin-top: 7.6rem;
		margin-bottom: 7.6rem;
	}

	.home-subheading__heading {
		@include Font32Medium;
	}

	.home-subheading__see {
		margin-top: 7rem;
	}

	.home-subheading__text {
		margin-top: 3.6rem;
		gap: 3.2rem;
		flex-direction: column;
	}

}
