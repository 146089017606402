@import 'typography';
@import 'colors';
@import 'breakpoints';

.c-home-numbers {
	margin-block: 16.4rem;
}

.home-numbers__texts {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	max-width: 52rem;
}

.home-numbers__heading {
	margin-bottom: 2.4rem;
}

.home-numbers__sub {
	@include Font24Regular;
	margin-bottom: 1.6rem;
	max-width: 45rem;
	margin-left: auto;
	margin-right: auto;

}

.home-numbers__sub2 {
	@include Font16Regular;
	color: $colorDegis;
	max-width: 45rem;
	margin-left: auto;
	margin-right: auto;
}

.home-numbers__box {
	border-radius: 2.4rem;
	margin-top: 6.4rem;
	border: 1px solid $colorCarbonGreyBg;
	padding: 4.4rem;
}

.home-numbers__below {
	@include Font20Regular;
	text-align: center;
	margin-top: 4.4rem;
}

.home-numbers__cols {
	display: flex;
	gap: 10rem
}

.home-numbers__col {
	flex: 1 1 0;
}

.home-numbers__col-heading {
	@include Font24Medium;
	margin-bottom: 1.2rem;
}

.home-numbers__row {
	padding-top: 2rem;
	padding-bottom: 2rem;
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;
}

.home-numbers__row--important {
	border-radius: 2.4rem;
	border: 4px solid #A45EFF;
	margin-left: -2.4rem;
	padding-left: 2rem;
	margin-right: -2.4rem;
	padding-right: 2rem;
	box-sizing: border-box;
}

@media only screen and (max-width: $breakpointSm) {

	.c-home-numbers {
		margin-block: 7.6rem;
	}

	.home-numbers__cols {
		flex-direction: column;
		gap: 4.8rem;
	}

	.home-numbers__box {
		margin-top: 4rem;
		padding: 3.2rem 2.8rem;
	}

}
