@import 'typography';
@import 'colors';

.tabs__tabs-box {
	display: flex;
}

.tabs__tab-item {
	flex: 1 1 0;
}

.tabs__tab-button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 4.4rem;
	@include Font12Bold;
	width: 100%;
	color: var(--base-tab-color, #{$colorDegis});
	border-bottom: solid 1px $colorCarbonGreyBg;
	transition: all 0.3s;
	cursor: pointer;

	&:hover {
		background: var(--hover-tab-bg, rgba($colorCarbonGreyBg, 0.3));
	}

}

.tabs__tab-button--active {
	border-bottom-color: var(--color);
	color: var(--color);
}
