@import 'colors';
@import 'breakpoints';

$fontSize: 12.4rem;
$fontSizeSm: 6.4rem;

$line: 1.15;

$totalHeight: $fontSize * $line;
$totalHeightSm: $fontSizeSm * $line;

$gap: 20rem;

.marquee__text {
	color: $colorDegis;
	font-size: $fontSize;
	font-weight: 500;
	white-space: nowrap;
	opacity: 0.16;
	line-height: $line;

	flex: 0 0 auto;
	padding-right: $gap;
}

.marquee__text--home {
	font-weight: bold;
}

.marquee__wrap {
	width: 100%;
	overflow: hidden;
	height: $totalHeight;
	position: relative;
}

.marquee__mover {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	height: $totalHeight;
	will-change: transform;
	transform: translateX(calc(-1 * (100% / 3) * var(--progress, 0)));
}

.marquee__mover--animated {
	transition: transform 1.05s linear;
}


@media only screen and (max-width: $breakpointSm) {

	.marquee__text {
		font-size: 4.4rem;
		font-weight: 500;
	}

	.marquee__text--home {
		font-size: 6.4rem;
		font-weight: bold;
	}


	.marquee__wrap {
		height: $totalHeightSm;
	}

	.marquee__mover {
		height: $totalHeightSm;
	}


}

