@import 'colors';
@import 'typography';

.login-form__heading {
	color: $colorBlackPearl;
	margin-bottom: 1.2rem;
}

.login-form__login-subheading {
	color: $colorCarbon60;
	@include Font16Regular;
	margin-bottom: 4.4rem;
}

.login-form__fields {
	margin-top: 4.4rem;
	margin-bottom: 2.4rem;
}

.login-form__field {
	display: block;
	margin-bottom: 1.2rem;
	position: relative;
}

.login-form__field-icon {
	position: absolute;
	left: 1.2rem;
	top: 1rem;
}

.login-form__field-icon-img {
	width: 2.4rem;
	height: 2.4rem;
}

.login-form__below-fields {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include Font14Bold;
	font-weight: 500;
	margin-bottom: 2.4rem;
}

.login-form__chkb {
	margin-right: 0.8rem;
}

.login-form__forgot {
	color: $colorViolet;
	text-align: right;

	&:hover, &:focus {
		text-decoration: underline;
	}
}

.login-form__legal {
	margin-top: 3.2rem;
	@include Font14Regular;

	a {
		color: $colorViolet;
		text-decoration: underline;
	}
}

// Breakpoint from real-world
@media only screen and (max-width: 389px) {
	.login-form__below-fields {
		flex-direction: column;
		gap: 1.6rem;
		align-items: stretch;
	}
}
