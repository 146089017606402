.marquee__text {
  color: #D4D4E5;
  font-size: 12.4rem;
  font-weight: 500;
  white-space: nowrap;
  opacity: 0.16;
  line-height: 1.15;
  flex: 0 0 auto;
  padding-right: 20rem;
}

.marquee__text--home {
  font-weight: bold;
}

.marquee__wrap {
  width: 100%;
  overflow: hidden;
  height: 14.26rem;
  position: relative;
}

.marquee__mover {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  height: 14.26rem;
  will-change: transform;
  transform: translateX(calc(-1 * (100% / 3) * var(--progress, 0)));
}

.marquee__mover--animated {
  transition: transform 1.05s linear;
}

@media only screen and (max-width: 980px) {
  .marquee__text {
    font-size: 4.4rem;
    font-weight: 500;
  }

  .marquee__text--home {
    font-size: 6.4rem;
    font-weight: bold;
  }

  .marquee__wrap {
    height: 7.36rem;
  }

  .marquee__mover {
    height: 7.36rem;
  }
}