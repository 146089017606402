.c-solution-options {
  margin-bottom: 12.4rem;
  margin-top: 16.4rem;
}

.solution-options__heading {
  margin-bottom: 6.4rem;
  text-align: center;
}

.solution-options__logo-row {
  display: block;
  line-height: 1px;
  margin-bottom: 1.2rem;
}

.solution-options__logo {
  height: 5.6rem;
  width: auto;
}

.solution-options__box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas: "a1 a2" "a3 a4" "a5 a5";
  gap: 2.4rem;
}

.solution-options__item {
  border-radius: 2.4rem;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 16rem 3.2rem 11.2rem;
  background: #2E2C48 center center no-repeat;
  background-size: cover;
  transition: background-color 0.3s;
  cursor: pointer;
  z-index: 1;
}

.solution-options__item--1 {
  grid-area: a1;
  background-image: url(../imgs/solution1.svg);
}

.solution-options__item--2 {
  grid-area: a2;
  background-image: url(../imgs/solution2.svg);
}

.solution-options__item--3 {
  grid-area: a3;
  background-image: url(../imgs/solution3.svg);
}

.solution-options__item--4 {
  grid-area: a4;
  background-image: url(../imgs/solution4.svg);
}

.solution-options__item--5 {
  grid-area: a5;
  background-image: url(../imgs/solution5.svg);
}

.solution-options__content {
  /* 20 Regular */
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 4.4rem;
}

.solution-options__x {
  position: absolute;
  left: 3.2rem;
  top: 3.2rem;
}

.solution-options__arrow {
  position: absolute;
  left: 3.2rem;
  bottom: 3.2rem;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 4.8rem;
  border: solid 1px rgba(251, 250, 253, 0.12);
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s;
}
.solution-options__arrow::after {
  width: 2.4rem;
  height: 2.4rem;
  content: "";
  background: url("../imgs/arrow-right.svg") no-repeat center;
  background-size: 100% auto;
  display: inline-block;
}

.solution-options__item:not(.solution-options__item--expanded) .solution-options__content {
  display: none;
}
.solution-options__item:not(.solution-options__item--expanded):hover, .solution-options__item:not(.solution-options__item--expanded):focus {
  background-color: #363455;
}
.solution-options__item:not(.solution-options__item--expanded):hover .solution-options__arrow, .solution-options__item:not(.solution-options__item--expanded):focus .solution-options__arrow {
  background-color: rgba(255, 255, 255, 0.12);
}
.solution-options__item:not(.solution-options__item--expanded):hover .solution-options__arrow::after, .solution-options__item:not(.solution-options__item--expanded):focus .solution-options__arrow::after {
  animation: arrow-bounce 0.5s infinite alternate;
}

.solution-options__arrow:hover {
  background-color: #363455;
}

.solution-options__item--expanded {
  z-index: 2;
  transition: none;
}
.solution-options__item--expanded .solution-options__arrow {
  transform: rotate(180deg);
}
.solution-options__item--expanded .solution-options__arrow::after {
  animation: none;
}

@keyframes arrow-bounce {
  from {
    transform: none;
  }
  to {
    transform: translateX(0.5rem);
  }
}
@media only screen and (min-width: 580px) {
  .solution-options__item--1.solution-options__item--expanded {
    grid-area: none;
    grid-column: 1/span 2;
    grid-row: 1;
  }

  .solution-options__item--2.solution-options__item--expanded {
    grid-area: none;
    grid-column: 1/span 2;
    grid-row: 1;
  }

  .solution-options__item--4.solution-options__item--expanded {
    grid-area: none;
    grid-column: 1/span 2;
    grid-row: 2;
  }

  .solution-options__item--3.solution-options__item--expanded {
    grid-area: none;
    grid-column: 1/span 2;
    grid-row: 2;
  }
}
@media only screen and (min-width: 580px) and (max-width: 980px) {
  .solution-options__item {
    padding-top: 12rem;
  }
}
@media only screen and (max-width: 580px) {
  .solution-options__item {
    padding: 12rem 2.4rem 9rem;
  }

  .solution-options__x {
    position: absolute;
    left: 2.4rem;
    top: 2.4rem;
  }

  .solution-options__arrow {
    left: 2.4rem;
    bottom: 2.4rem;
  }

  .solution-options__box {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas: "a1" "a2" "a3" "a4" "a5";
  }
}