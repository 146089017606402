@import 'typography';
@import 'colors';
@import 'breakpoints';

.c-home-violet-block {
	margin-block: 16.4rem;
	padding-bottom: 12.4rem;
}

.home-violet-block__heading {
	--color: #{$colorViolet};
	--color-bg: #{$colorWhite};
	width: fit-content;
	margin: 0 auto 6.4rem;
	display: block;
}

.home-violet-block__with-box {
	box-sizing: border-box;
	text-align: center;
	position: relative;
	width: 58.5rem;
	height: 38rem;
	margin: -1.6rem auto;
}

.home-violet-block__path-svg {
	width: 100%;
	height: 100%;
}

.home-violet-block__with {
	background: url(../imgs/with-xploritas.svg) center center no-repeat;
	background-size: auto 12.4rem;
	position: absolute;
	inset: 0;
}

.home-violet-block__text {
	@include Font24Medium;
	max-width: 50rem;
	text-align: center;
	margin-left: auto;
	margin-right:  auto;
}

.home-violet-block__button-space {
	margin-top: 4.8rem;
	text-align: center;
}

.home-violet-block__x {
	position: absolute;
	left: calc(var(--x) * 100% - 1.6rem);
	top: calc(var(--y) * 100% - 1.6rem);
}

// Tablets

@media only screen and (min-width: $breakpointXs) and (max-width: $breakpointSm) {

	.home-violet-block__with-box {
		// Original size * 0.8
		width: 46.8rem;
		height: 30.4rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

}

// Mobiles

@media only screen and (max-width: $breakpointXs) {

	.c-home-violet-block {
		padding-top: 7.6rem;
		padding-bottom: 7.6rem;
		margin-bottom: 7.6rem;
		margin-top: 7.6rem;
	}

	.home-violet-block__with-box {
		width: 100%;
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		height: 26.5rem;
		background: url('../imgs/with-exploritas-mobile.svg') center center no-repeat;
		background-size: auto 100%;
	}

	.home-violet-block__with {
		height: 9rem;
		top: 8rem;
		background-size: auto 9rem;
	}

	.home-violet-block__path-svg {
		display: none;
	}

	.home-violet-block__x {
		display: none;
	}

}

// Small mobiles needs smaller "With exploritas" block

@media only screen and (max-width: 385px) {

	.home-violet-block__with-box {
		height: 24rem;
		background: url('../imgs/with-exploritas-mobile.svg') center center no-repeat;
		background-size: auto 100%;
	}

	.home-violet-block__with {
		top: 7rem;
	}

}
