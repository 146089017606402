@import 'breakpoints';

.c-about-us-text1 {
	margin-top: 13.6rem;
	margin-bottom: 16.4rem;
}

.about-us-text1__body {
	position: relative;
}

.about-us-text1__deco {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 42rem;
}

.about-us-text1__deco-svg {
	width: 100%;
	height: 100%;
}

.about-us-text1__x-pos {
	position: absolute;
	left: calc(var(--x, 1) * 100% - 1.6rem);
	top: calc(var(--y, 0) * 100% - 1.6rem);
}

@media only screen and (max-width: $breakpointSm) {

	.c-about-us-text1 {
		margin-block: 7.6rem;
	}

	.about-us-text1__deco {
		display: none;
	}

}
