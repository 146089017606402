
@import 'typography';
@import 'breakpoints';

.c-data-repository-benefits {
	margin-block: 16.4rem;
}

.data-repository-benefits__heading {
	margin-bottom: 6.4rem;
	text-align: center;
}

.data-repository-benefits__subtitle {
	@include Font24Medium;
	text-align: center;
	margin-bottom: 4.4rem;
}

.data-repository-benefits__box {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: auto auto;
	grid-template-areas: 'f1 f1 f2 f2 f3 f3' 'f4 f4 f4 f5 f5 f5';
	gap: 2.4rem;
}



@for $i from 1 through 5 {
	.data-repository-benefits__field--#{$i} {
		grid-area: f#{$i};
		--stagger: #{$i - 1};
	}
}

.data-repository-benefits__swipe-help {
	display: none;
	@include Font14Bold;
	margin-top: 1.6rem
}

.data-repository-benefits__scroll-padding-placeholder {
	display: none;
}


.data-repository-benefits__animated {
	.data-repository-benefits__field {
		transition: all 0.3s calc(var(--stagger, 0) * 0.05s);
	}

	&:not(.data-repository-benefits__box--entered) {
		.data-repository-benefits__field {
			opacity: 0;
			visibility: hidden;
			transform: translateY(5rem);
		}
	}
}

@media only screen and (min-width: $breakpointXs) and (max-width: $breakpointSm) {
	.data-repository-benefits__box {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto auto auto;
		grid-template-areas: 'f1 f2' 'f3 f4' 'f5 f5';
		gap: 2.4rem;
	}
}


@media only screen and (max-width: $breakpointXs) {

	.c-data-repository-benefits {
		margin-block: 7.6rem;
	}

	.data-repository-benefits__box {
		grid-template-columns: repeat(5, 28rem) 1px;
		grid-template-rows: auto;
		grid-template-areas: 'f1 f2 f3 f4 f5 .';
		gap: 2.4rem;
	}

	.data-repository-benefits__scroll-parent {
		width: 100%;
		overflow: auto;
		padding-bottom: 1.6rem;
		padding-left: $paddingXs;
		box-sizing: border-box;
	}

	.data-repository-benefits__swipe-help {
		padding-left: $paddingXs;
		display: block;
	}

	.data-repository-benefits__scroll-padding-placeholder {
		display: block;
	}

}

