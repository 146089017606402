@import 'colors';
@import 'typography';
@import 'breakpoints';

.c-easy-to-deploy {
	margin-top: 13.2rem;
	margin-bottom: 17.2rem;
}

.easy-to-deploy__body {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 2.4rem;
	row-gap: 8rem;
	grid-template-rows: 35.2rem 37.6rem 42.2rem 47.2rem;
	grid-template-areas: 'a .' '. b' 'c .' '. d';
	position: relative;
}

.easy-to-deploy__step {
	display: grid;
	grid-template-columns: 10.4rem 1fr;
	grid-template-rows: 10.4rem auto;
	column-gap: 2.4rem;
	row-gap: 3.2rem;
	width: 50.4rem;
	position: relative;
	z-index: 2;
}

.easy-to-deploy__step--a {
	grid-area: a;
}

.easy-to-deploy__step--b {
	grid-area: b;
}

.easy-to-deploy__step--c {
	grid-area: c;
}

.easy-to-deploy__step--d {
	grid-area: d;
}


.easy-to-deploy__number {
	grid-column: 1;
	grid-row: 1;
	border-radius: 10.4rem;
	font-size: 3.2rem;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--color-bg, #31334D); // 12% white, but without transparency
	color: var(--color, $colorWhite);
}

.easy-to-deploy__heading {
	grid-column: 2;
	grid-row: 1;
	align-self: center;
	transition: opacity 0.5s;
}

.easy-to-deploy__content {
	grid-column: 2;
	grid-row: 2;
	color: $colorDegis;
	@include Font16Regular;
	transition: opacity 0.5s;

	ul {
		margin: 0;
	}
}

.easy-to-deploy__deco {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	z-index: 1;
}

.easy-to-deploy__deco-svg {
	width: 63rem;
	height: 193rem;
}

.easy-to-deploy__x {
	position: absolute;
	left: calc(var(--x, 0.05) * 100% - 1.6rem);
	top: calc(var(--y, 0.03) * 100% - 1.6rem);
}


// Desktops

@media only screen and (min-width: $breakpointSm) {

	.easy-to-deploy__step--faded {
		.easy-to-deploy__heading, .easy-to-deploy__content {
			opacity: 0.4;
		}
	}

}

// Tablets and mobiles

@media only screen and (max-width: $breakpointSm) {


	.c-easy-to-deploy {
		margin-top: 7.6rem;
		margin-bottom: 14rem;
	}

	.easy-to-deploy__body {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: none;
		row-gap: 7.6rem;
		grid-auto-rows: auto;
		grid-template-areas: 'a' 'b' 'c' 'd';
		position: relative;
	}

	.easy-to-deploy__deco {
		display: none;
	}

	.easy-to-deploy__step {
		display: grid;
		grid-template-columns: 6.4rem 1fr;
		grid-template-rows: minmax(6.4rem, auto) auto;
		row-gap: 4rem;
		width: auto;
	}

	.easy-to-deploy__step--faded {
		.heading, .content {
			opacity: 1;
		}
	}

	.easy-to-deploy__number {
		width: 6.4rem;
		height: 6.4rem;
		font-size: 2rem;
	}

	.easy-to-deploy__content {
		grid-column: 1 / span 2;

		ul {
			margin-top: 1em;
		}
	}

}
