@import 'colors';

.g-select {

		height: 4.4rem;
		box-sizing: border-box;
		padding-inline: 1.4rem;
		@include Font16Regular;
		color: $colorCarbon60;
		border: none;
		appearance: none;
		border-radius: 0.8rem;
		background: rgba(232, 231, 236, 0.50) url(../imgs/chevron-down-black.svg) no-repeat right 1rem center;
		background-size: 2rem auto;
		width: 100%;
		font-family: inherit;
		outline: none;

		&:hover {
			background-color: rgba(232, 231, 236, 0.8);
		}

		&:focus {
			background-color: rgba(232, 231, 236, 1);
			color: $colorBlackPearl;
		}

		&::placeholder {
			color: $colorCarbon60;
			opacity: 0.75;
		}

}
