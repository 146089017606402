@import 'colors';
@import 'breakpoints';

hr {
	margin-block: 6.4rem;
	border: none;
	border-top: solid 1px rgba($colorWhite, 0.12);
}

@media only screen and (max-width: $breakpointSm) {
	hr {
		margin-block: 4.8rem;
	}
}
