@import 'colors';
@import 'breakpoints';
@import 'typography';

.c-faq {
	margin-top: 18.4rem;
	margin-bottom: 18.4rem;
}

.faq__heading {
	margin-bottom: 6.4rem;
}

.faq__item {
	border-radius: 2.4rem;
	transition: all 0.3s;
	margin-bottom: 0.4rem;
	margin-top: 0.4rem;
	position: relative;
}

.faq__question-button {
	@include Font20Medium;
	color: $colorWhite;
	padding: 4rem 9rem 4rem 4.4rem;
	margin: 0;
	position: relative;
	width: 100%;
	box-sizing: border-box;
	text-align: left;
	transition: all 0.2s;
	border-radius: 2.4rem;

	&::before {
		content: '';
		width: 2.4rem;
		height: 2.4rem;
		position: absolute;
		right: 4.4rem;
		top: calc(50% - 1.2rem);
		transition: all 0.3s;
		background: url('../imgs/chevron-down.svg') no-repeat center center;
		background-size: 2.4rem auto;
	}

	&:focus, &:hover {
		background: rgba($colorCarbonGreyBg, 0.5);
	}

	&::after {
		position: absolute;
		left: 4rem;
		right: 4rem;
		bottom: -0.4rem;

		content: '';
		display: block;
		border-bottom: solid 1px $colorCarbonGreyBg;
	}
}

.faq__answer {
	@include Font16Regular;
	color: $colorDegis;
	transition: all 0.3s;
	margin: 0;
	padding: 0 4.4rem 4.4rem 4.4rem;
	position: relative;

	&::before {
		content: '';
		width: 100%;
		display: block;
		border-top: solid 1px rgba($colorDegis, 0.25);
		padding-bottom: 3.2rem;
	}

}


.faq__item--expanded {

	background: $colorCarbonGreyBg;

	.faq__answer {
		max-height: var(--h, 50rem);
	}

	.faq__question-button {


		&::before {
			transform: rotate(180deg);
		}
	}
}

.faq__item:not(.faq__item--expanded) {

	background: rgba($colorCarbonGreyBg, 0);

	.faq__answer {
		opacity: 0;
		max-height: 0;
		visibility: hidden;
		transform: translateY(-1rem);
		padding-bottom: 0;
	}

}

@media only screen and (max-width: $breakpointXs) {

	.faq__faq {
		margin-top: 8.4rem;
		margin-bottom: 10rem;
	}

	.faq__question-button {
		padding: 2.4rem;
		padding-right: 7rem;

		&::before {
			right: 2.4rem;
		}

		&::after {
			left: 2.4rem;
			right: 2.4rem;
		}
	}

	.faq__answer {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
		padding-bottom: 2.4rem;

		&::before {
			padding-bottom: 2.4rem;
		}
	}

}
