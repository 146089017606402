@import 'colors';
@import 'typography';


.g-input {

	height: 4.4rem;
	box-sizing: border-box;
	padding-inline: 1.4rem;
	@include Font16Regular;
	color: $colorCarbon60;
	border: solid 1px transparent;
	appearance: none;
	border-radius: 0.8rem;
	background: rgba(232, 231, 236, 0.50);
	width: 100%;
	font-family: inherit;
	outline: none;

	&:hover {
		background: rgba(232, 231, 236, 0.65);
	}

	&:focus {
		background: rgba(232, 231, 236, 0.9);
		color: $colorBlackPearl;
	}

	&::placeholder {
		color: $colorCarbon60;
		opacity: 0.75;
	}

}

.g-input.g-input--touched:invalid, .g-form--touched .g-input:invalid {
	border: solid 0.15rem $colorRed;
	color: $colorRed;

	&::placeholder {
		color: $colorRed;
	}
}

.g-input--textarea {
	min-height: 13rem;
	padding-block: 0.8rem;
}

.g-input--white {
	border: 1px solid $colorCarbonGrey;
	background: none;

	&:hover {
		border: 1px solid mix($colorCarbonGrey, $colorCarbon60, 85%);
		background: none;
	}

	&:focus {
		border: 1px solid mix($colorCarbonGrey, $colorCarbon60, 70%);
		background: none;
	}
}

.g-input--with-icon {
	padding-left: 4.4rem;
}
