.c-marquee-footer {
  margin-top: 24rem;
  margin-bottom: 22rem;
}

.marquee-footer__body {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.8rem;
}

.marquee-footer__big {
  font-size: 12.4rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
}

.marquee-footer__sub {
  color: rgba(212, 212, 229, 0.42);
  font-size: 3.4rem;
  line-height: 1.35;
}

@media only screen and (max-width: 980px) {
  .c-marquee-footer {
    margin-top: 7.6rem;
    margin-bottom: 14rem;
  }

  .marquee-footer__body {
    margin-top: 3.2rem;
  }

  .marquee-footer__big {
    font-size: 8rem;
  }
}
@media only screen and (min-width: 580px) and (max-width: 980px) {
  .c-marquee-footer {
    margin-top: 12rem;
  }
}
@media only screen and (max-width: 580px) {
  .marquee-footer__body {
    width: 100%;
    box-sizing: border-box;
    margin-top: 1.2rem;
    padding-inline: 2.4rem;
  }

  .marquee-footer__sub {
    /* 24 Regular */
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.333;
    max-width: 17ch;
  }
}