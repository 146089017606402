$fontBase: 'DM Sans', sans-serif;

@mixin Font12Medium {

	/* 12 Medium */
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.333;

}

@mixin Font12Bold {

	/* 12 Medium */
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.333;

}

@mixin Font14Bold {

	/* 14 Medium */
	font-size: 1.4rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.333;

}

@mixin Font14Regular {

	/* 14 Regular */
	font-size: 1.4rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.6;

}


@mixin Font16Regular {

	/* 16 Regular */
	font-size: 1.6rem;
	font-style: normal;
	font-weight: normal;
	line-height: 1.5;

}


@mixin Font16Medium {

	/* 16 Medium */
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5;

}


@mixin Font24Regular {

	/* 24 Regular */
	font-size: 2.4rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.333;

}

@mixin Font24Medium {

	/* 24 Regular */
	font-size: 2.4rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.333;

}

@mixin Font24Bold {

	/* 24 Bold */
	font-size: 2.4rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.333;

}

@mixin Font20Regular {

	/* 20 Regular */
	font-size: 2rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4;

}

@mixin Font20Bold {

	font-size: 2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.4;

}


@mixin Font20Medium {

	font-size: 2rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.4;

}



@mixin Font64Medium {

	/* 64 Medium */
	font-size: 6.4rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.4;

}

@mixin Font32Medium {

	/* 32 Medium */
	font-size: 3.2rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.4;

}


@mixin Font28Medium {

	/* 28 Medium */
	font-size: 2.8rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.4;

}

@mixin Font26Medium {

	/* 26 Medium */
	font-size: 2.6rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.4;

}

@mixin Font36Medium {

	/* 36 Medium */
	font-size: 3.6rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.4;

}



@mixin Font40Medium {

	/* 40 Medium */
	font-size: 4rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.4;
}



@mixin Font44Medium {

	/* 44 Medium */
	font-size: 4.4rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.4;
}
