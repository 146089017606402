@import 'colors';

.x-in-circle__colored {
	transition: all 0.3s;
	fill: var(--color, $colorOrange);
}

.x-in-circle__x {
	width: var(--size, 100%);
	height: var(--size, 100%);
}

.x-in-circle__bg-colored {
	fill: var(--color-bg, rgba(white, 0.1));
}
