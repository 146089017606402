.c-home-x-footer {
  margin-top: 16.4rem;
  margin-bottom: 9.6rem;
}

.home-x-footer__crosshair {
  margin-left: auto;
  margin-right: auto;
  width: 32.4rem;
  height: 32.4rem;
  background: url("../imgs/crosshair.svg") no-repeat center center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12.4rem;
}

.home-x-footer__text {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  /* 24 Regular */
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.333;
  text-align: center;
}

@media only screen and (max-width: 580px) {
  .c-home-x-footer {
    margin-top: 7.6rem;
    margin-bottom: 5.6rem;
  }
}